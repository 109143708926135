import { VFC } from 'react';

import 'styles/organisms/Terms.scss';

const Terms: VFC = () => (
  <div id="common-document-format">
    <div className="default">
      本 ViRTH 利用規約（以下「本規約」といいます。）には、株式会社
      kiCk（以下「当社」といいます。）の提供する本サービス（第 2
      条に定義）のご利用にあたり、ユーザーの皆様に遵守していただかなければならない事項及び当社とユーザーの皆様との間の権利義務関係が定められております。本サービスをご利用になる方は、本規約に同意する前に、必ず全文お読み下さいますようお願い致します。
    </div>
    <div className="paragraph-title">第 1 条 適用</div>
    <div className="default">
      1. 本規約は、本サービス（第 2 条に定義）の利用に関する当社とユーザー（第 2
      条に定義）との間の権利義務関係を定めることを目的とし、ユーザーと当社の間の本サービスの利用に関わる一切の関係に適用されます。
      <br />
      2. 当社が当社ウェブサイト（第 2
      条に定義）上で随時掲載する本サービスに関する
      ルール、諸規定等は本規約の一部を構成するものとします。
    </div>
    <div className="paragraph-title">第 2 条 定義</div>
    <div className="default">
      本規約において使用する以下の用語は各々以下に定める意味を有するものとします。
      <br />
      1.
      本サービス」とは、当社が提供する「ViRTH」という名称の、ユーザーがパーティーの開催及び参加ができるサービス（理由の如何を問わずサービスの名称又は内容が変更された場合は、当該変更後のサービスを含みます。）を意味します。
      <br />
      2. 「外部サービス」とは、他の事業者（Google、Apple、Facebook、Rentio、Epic
      Online Services
      を含みますが、これらに限りません。）が提供している当社所定の本サービスの実施に利用されるサービスを意味します。
      <br />
      3.
      「知的財産権」とは、著作権、特許権、実用新案権、商標権、意匠権その他の知的財産権（それらの権利を取得し、又はそれらの権利につき登録等を出願する権利を含みます。）を意味します。
      <br />
      4.「「当社ウェブサイト」とは、そのドメインが「virth.jp」である当社が運営するウェブサイト（理由の如何を問わず当社のウェブサイトのドメイン又は内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します。
      <br />
      5. 「登録希望者」とは、第 3
      条において定義された「登録希望者」を意味します。
      <br />
      6. 「登録情報」とは、第 3 条において定義された「登録情報」を意味します。
      <br />
      7.
      「パーティー」とは、ホストが選択したワールドにメインゲストやゲストが参加することができるお祝いイベントを意味します。
      <br />
      8.
      「ホスト」とは、ユーザーのうち、本サービスでパーティーを開催する主催者を意味します。
      <br />
      9.
      「メインゲスト」とは、ユーザーのうち、招待されたパーティーに参加する主役を意味します。
      <br />
      10.「ゲスト」とは、ユーザーのうち、招待されたパーティーに参加する出席者を意味します。
      <br />
      11.「ユーザー」とは、第 3
      条に基づき、本サービス上で当社の定める必要な情報を登録し、当社により当社独自の
      ID（以下「ID」といいます）の利用につき承認を受けた者を意味します。
      <br />
      12.「ユーザーコンテンツ」とは、ワールド内に存在する映像、画像、音声、テキスト、ユーザーに関する情報などの一切のコンテンツのうちユーザーが設定及び作成したコンテンツ並びにユーザー作成パーティー及びユーザー作成アバターを意味します。
      <br />
      13.「ユーザー作成アバター」とは、ユーザーが本サービス上にアップロードしたアバターのデータを意味します。
      <br />
      14.「会場デコレーション」とは、ユーザーが作成し本サービス上にデコレーションしたパーティー会場のデータを意味します。
      <br />
      15.「利用契約」とは、第 3 条第 4 項に定義される「利用契約」を意味します。
      <br />
      16.「ワールド」とは、当社が提供する、一般参加者がパーティー会場としてパーティーの開催及び参加することができる仮想空間を意味します。
      <br />
      17.「対応端末」とは、当社が、本サービスを利用することができる端末として、別途本サービス情報サイト上で指定する端末を意味します。
    </div>
    <div className="paragraph-title">第 3 条 登録</div>
    <div className="default">
      1.
      本サービスの利用を希望する者（以下「登録希望者」といいます。）は、本規約を遵守することに同意し、かつ当社の定める一定の情報（以下「登録情報」といいます。）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができます。
      <br />
      2.
      登録の申請は必ず本サービスを利用する個人又は法人自身が行わなければならず、原則として代理人による登録申請は認められません。また、登録希望者は、登録の申請にあたり、真実、正確かつ最新の情報を当社に提供しなければなりません。
      <br />
      3. 当社は、第 1
      項に基づき登録を申請した者が、以下の各号のいずれかの事由に該当する場合は、登録を拒否することがあります。
      <br />
      &nbsp;1. 本規約に違反するおそれがあると当社が判断した場合
      <br />
      &nbsp;2.
      当社に提供された登録情報の全部又は一部につき虚偽、誤記又は記載漏れがあった場合
      <br />
      &nbsp;3.
      過去に本サービス又は当社が提供する他のサービスの利用の登録を取り消された者である場合
      <br />
      &nbsp;4.
      未成年者、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見人､保佐人又は補助人の同意等を得ていなかった場合
      <br />
      &nbsp;5.
      反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、又は資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合
      <br />
      &nbsp;6. その他、当社が登録を適当でないと判断した場合
      <br />
      4.
      当社は、前項その他当社の基準に従って、登録希望者の登録の可否を判断し、当社が登録を認める場合にはその旨を登録希望者に通知します（登録希望者が当社ウェブサイト上で本サービスの利用が可能な状態となった場合には、これをもって登録を認める通知があったものとみなします。）。かかる通知により登録希望者のユーザーとしての登録は完了し、本規約の諸規定に従った本サービスの利用にかかる契約（以下「利用契約」といいます。）がユーザーと当社の間に成立します。
      <br />
      5.
      ユーザーは、登録情報に変更があった場合は、遅滞なく、当社の定める方法により、当該変更事項を当社に通知し、当社から要求された資料を提出するものとします。これを怠ったことによるユーザーの不利益について、当社は責任を負いません。
    </div>
    <div className="paragraph-title">第 4 条 本サービスの利用</div>
    <div className="default">
      ユーザーは、利用契約の有効期間中、本規約に従って、当社の定める方法に従い、本サービスを利用することができます。ユーザーは、ホストとして本サービス上でパーティーを開催することができ、またメインゲストやゲストとして招待されたパーティー会場をデコレーションすること及びパーティーに参加することができます。
    </div>
    <div className="paragraph-title">第 5 条 アカウント情報の管理</div>
    <div className="default">
      ユーザーは、自己の責任において、本サービスにかかるユーザーID
      及びパスワード（以下「アカウント情報」といいます。）を管理及び保管するものとし、これを第三者に利用させたり、貸与、譲渡、名義変更、売買等をしてはならないものとします。
      <br />
      2.
      アカウント情報の管理不十分、使用上の過誤、第三者の使用等による損害の責任はユーザーが負うものとし、当社は一切の責任を負いません。
      <br />
      3.
      ユーザーは、アカウント情報が盗まれ、又は第三者に使用されていることが判明した場合には、直ちにその旨を当社に通知するとともに、当社からの指示がある場合はそれに従うものとします。
    </div>
    <div className="paragraph-title">第 6 条 禁止行為</div>
    <div className="default">
      1.
      ユーザーは、以下の各号のいずれかに該当するユーザーコンテンツを本サービス上に配信、アップロード又は送信（以下本条において「配信等」といいます。）してはなりません。
      <br />
      &nbsp;1.
      当社、又は他のユーザーその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉・信用、その他の権利又は利益を侵害する、又は侵害するおそれのあるユーザーコンテンツの配信等
      <br />
      &nbsp;2. 性行為を目的としたユーザーコンテンツの配信等
      <br />
      &nbsp;3.
      犯罪行為に関連する行為又は公序良俗に反するユーザーコンテンツの配信等
      <br />
      &nbsp;4.
      本規約、法令又は当社若しくはユーザーが所属する業界団体の内部規則に違反するユーザーコンテンツの配信等
      <br />
      &nbsp;5.
      自己が権利を有していない音源であって、権利者の許可を得ていない音源が使用されているユーザーコンテンツの配信等
      <br />
      &nbsp;6.
      他のユーザーその他の第三者になりすまして行うユーザーコンテンツの配信等
      <br />
      &nbsp;7.
      ユーザーコンテンツの説明等に虚偽の情報が含まれるユーザーコンテンツの配信等
      <br />
      &nbsp;8.
      本サービスの運営を妨げる又は当社の信用を毀損するおそれのあるユーザーコンテンツの配信等
      <br />
      &nbsp;9. 公職選挙法に抵触するユーザーコンテンツの配信等
      <br />
      &nbsp;10.本サービス上の手続によらずにユーザーから対価を得て行うユーザーコンテンツの配信等
      <br />
      &nbsp;11.他のユーザーその他の第三者が迷惑や不快感を感じるおそれのあるユーザーコンテンツの配信等
      <br />
      &nbsp;12.その他、当社が不適当と判断するユーザーコンテンツの配信等
      <br />
      2. ユーザーコンテンツの配信等が第 1
      項各号のいずれかに該当すると当社が判断した場合には、当社はユーザーに事前に通知することなく、当該ユーザーコンテンツの配信等の停止、当該ユーザーコンテンツを含むワールド及びイベントの削除その他の必要な措置を講ずることができるものとします。
      <br />
      3. ユーザーは、本サービスの利用にあたり、第 1
      項各号に掲げる行為のほか以下の各号のいずれかに該当する行為をしてはなりません。
      <br />
      &nbsp;1.
      当社、又は他のユーザー、外部事業者その他の第三者の知的財産権、肖像権、プライバシーの権利、名誉・信用、その他の権利又は利益を侵害する行為（かかる侵害を直接又は間接に惹起する行為を含みます。）
      <br />
      &nbsp;2. 犯罪行為に関連する行為又は公序良俗に反する行為
      <br />
      &nbsp;3. 猥褻な情報又は青少年に有害な情報を送信する行為
      <br />
      &nbsp;4. 異性交際に関する情報を送信する行為
      <br />
      &nbsp;5.
      本規約、法令又は当社若しくはユーザーが所属する業界団体の内部規則に違反する行為
      <br />
      &nbsp;6.
      コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報を送信する行為
      <br />
      &nbsp;7. 本サービスに関し利用しうる情報を改ざんする行為
      <br />
      &nbsp;8.
      当社が定める一定のデータ容量以上のデータを本サービスを通じて送信する行為
      <br />
      &nbsp;9. 当社の許諾を得ていない本サービスに関連する営利活動
      <br />
      &nbsp;10.当社による本サービスの運営を妨害するおそれのある行為
      <br />
      &nbsp;11.その他、当社が不適切と判断する行為
      <br />
      4.
      当社は、本サービスにおけるユーザーによる情報の送信行為が前項各号のいずれかに該当し、又は該当するおそれがあると当社が判断した場合には、ユーザーに事前に通知することなく、当該情報の全部又は一部を削除することができるものとします。
      <br />
      5. 当社は、本条に基づき当社が行った措置に基づきユーザーに生じた損害につい
      て、一切の責任を負いません。
    </div>
    <div className="paragraph-title">第 7 条 本サービスの停止等</div>
    <div className="default">
      1.
      当社は、以下のいずれかに該当する場合には、ユーザーに事前に通知することなく、本サービスの利用の全部又は一部を停止又は中断することができるものとします。
      <br />
      1.
      本サービスに係るコンピューター・システムの点検又は保守作業を定期的又は緊急に行う場合
      <br />
      2.
      コンピューター、通信回線等が事故（第三者による妨害を含む）により停止した場合
      <br />
      3.
      火災、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合
      <br />
      4.
      外部サービスに、トラブル、サービス提供の中断又は停止、本サービスとの連携の停止、仕様変更等が生じた場合
      <br />
      5. その他、当社が停止又は中断を必要と判断した場合
      <br />
      2.
      当社は、当社の都合により、本サービスの提供を終了することができます。この場合、当社はユーザーに事前に通知するものとします。
      <br />
      3.
      当社は、本条に基づき当社が行った措置に基づき生じた損害について一切の責任を負いません。
    </div>
    <div className="paragraph-title">第 8 条 設備の負担等</div>
    <div className="default">
      1.
      本サービスの提供を受けるために必要な、コンピューター、ソフトウェアその他の機器、通信回線その他の通信環境等の準備及び維持は、ユーザーの費用と責任において行うものとします。
      <br />
      2.
      ユーザーは自己の本サービスの利用環境に応じて、コンピューター・ウィルスの感染の防止、不正アクセス及び情報漏洩の防止等のセキュリティ対策を自らの費用と責任において講じるものとします。
      <br />
      3.
      当社は、ユーザーが送受信したメッセージその他の情報を運営上一定期間保存していた場合であっても、かかる情報を保存する義務を負うものではなく、当社はいつでもこれらの情報を削除できるものとします。なお、当社はかかる情報の削除に基づきユーザーに生じた損害について一切の責任を負いません。
      <br />
      4.
      ユーザーは、本サービスの利用開始に際し又は本サービスの利用中に、当社ウェブサイトからのダウンロードその他の方法によりソフトウェア等をユーザーのコンピューター等にインストールする場合には、ユーザーが保有する情報の消滅若しくは改変又は機器の故障、損傷等が生じないよう十分な注意を払うものとし、当社はユーザーに発生したかかる損害について一切責任を負わないものとします。
    </div>
    <div className="paragraph-title">第 9 条 権利帰属</div>
    <div className="default">
      当社ウェブサイト及び本サービスに関する所有権及び知的財産権は、次条に定めるユーザーコンテンツを除き、全て当社又は当社にライセンスを許諾している者に帰属します。本規約に定める登録に基づく本サービスの利用許諾は、本規約において明示されているものを除き、当社ウェブサイト又は本サービスに関する当社又は当社にライセンスを許諾している者の知的財産権の譲渡又は使用許諾を意味するものではありません。ユーザーは、いかなる理由によっても当社又は当社にライセンスを許諾している者の知的財産権を侵害するおそれのある行為をしないものとします。
    </div>
    <div className="paragraph-title">第 10 条 ユーザーコンテンツ</div>
    <div className="default">
      1.
      ユーザーコンテンツに関する知的財産権は、当該ユーザーコンテンツを作成したユーザーに帰属するものとします。但し、当該ユーザーは、当社に対し、当社が当該ユーザーコンテンツを、本サービスの提供及び宣伝広告の目的で、無償で自由に利用することができる権利を許諾するものとし、当社又は当社が利用を許諾した第三者に対して著作者人格権を行使しないものとします。なお、ユーザーが第
      4
      項に定める公開設定をしたか否かは、本項に基づくユーザーの当社に対する利用許諾に何ら影響を及ぼさないものとします。当社は、本サービスと連携する他社サービスの利用等のために当該他社サービスを運営する第三者等に対してユーザーコンテンツの利用を許諾する可能性はありますが、本サービスを離れてユーザーコンテンツを第三者に使用許諾することや、ユーザーコンテンツをグッズ化すること、その他ユーザーの同意なく本サービスの提供及び宣伝広告の目的以外にユーザーコンテンツを利用することは致しません。
      <br />
      2.
      ユーザーは、ユーザーコンテンツの作成に当たり第三者の権利を侵害しないように留意するものとし、自己が作成したユーザーコンテンツが第三者の権利を侵害していないことをユーザー自身において確認するものとします。ユーザーは、第三者の作成した著作物等をユーザーコンテンツに組み込む場合には、当該著作物等をユーザーコンテンツに組み込み本サービス上で利用すること及び当社が前項に基づきこれを利用することが、当該第三者との間の契約や規約等に違反しないことを事前に確認するものとし、当該第三者から許諾を得ること等の手続が必要となる場合には、ユーザーが自己の責任においてかかる手続を適切に行うものとします。第三者の著作物等をユーザーコンテンツに取り込むにあたり当該著作者の許諾を得なかったことにより当該第三者に生じた損害はユーザーが責任を負うものとします。
      <br />
      3. 第 1
      項及び前項に定めるものを除き、ワールド、パーティー、ワールド及びパーティー内に存在する一切のコンテンツに関する知的財産権は、全て当社に帰属するものとします。
      <br />
      4.
      ユーザーはパーティー内において自由に映像及びスクリーンショットを撮影できるものとし、かかる映像及びスクリーンショットの画像を自由に利用することができるものとします（以下かかる画像を「ワールド内スクリーンショット等」といいます。）。ユーザーは、自己のユーザーコンテンツを含むワールド内スクリーンショット等を他のユーザー及び当社が撮影し、自由に利用することができる権利を他のユーザー及び当社に対して許諾するものとし、これにつき当該他のユーザー及び当社に対して著作者人格権を行使しないものとします。
      <br />
      5.
      ユーザーは、ワールド内（パーティー内を含みます。）のユーザーの活動（ユーザーのアバター（ユーザー作成アバターのみならず、ユーザーが選択した当社所定のオリジナルアバターを含みます。）の外見等及びユーザーのコメントを含みますが、これらに限りません。）が、本サービス、その他インターネットを通じて他のユーザーその他の第三者から視聴可能な状態となることにつきあらかじめ同意し、これについて何らの異議を唱えないものとします。また、当社は、これによりユーザーに生じた損害について一切の責任を負わないものとします。
    </div>
    <div className="paragraph-title">第 11 条 登録取消等</div>
    <div className="default">
      1.
      当社は、ユーザーが、以下の各号のいずれかの事由に該当する場合は、事前に通知又は催告することなく、当該ユーザーについて本サービスの利用を一時的に停止し、又はユーザーとしての登録を取り消すことができます。
      <br />
      &nbsp;1. 本規約のいずれかの条項に違反した場合
      <br />
      &nbsp;2. 登録情報に虚偽の事実があることが判明した場合
      <br />
      &nbsp;3.
      当社、他のユーザー、外部事業者その他の第三者に損害を生じさせるおそれのある目的又は方法で本サービスを利用した、又は利用しようとした場合
      <br />
      &nbsp;4.
      外部利用規約に違反したことその他の理由によって、ユーザーが外部事業者から、そのサービスの提供や連携を受けられなくなった場合
      <br />
      &nbsp;5. 手段の如何を問わず、本サービスの運営を妨害した場合
      <br />
      &nbsp;6.
      支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合
      <br />
      &nbsp;7.
      自ら振出し、若しくは引受けた手形若しくは小切手につき、不渡りの処分を受けた場合、又は手形交換所の取引停止処分その他これに類する措置を受けたとき
      <br />
      &nbsp;8. 差押、仮差押、仮処分、強制執行又は競売の申立てがあった場合
      <br />
      &nbsp;9. 租税公課の滞納処分を受けた場合
      <br />
      &nbsp;10.死亡した場合又は後見開始、保佐開始若しくは補助開始の審判を受けた場合
      <br />
      &nbsp;11.6
      ヶ月以上本サービスの利用がなく、当社からの連絡に対して応答がない場合
      <br />
      &nbsp;12.第 3 条第 3 項各号に該当する場合
      <br />
      &nbsp;13.その他、当社がユーザーとしての登録の継続を適当でないと判断した場合
      <br />
      2.
      前項各号のいずれかの事由に該当した場合、ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
      <br />
      3.
      ユーザーは、当社所定の方法で相手方に通知することにより、ユーザーの登録を取り消すことができます。
      <br />
      4.
      当社は、本条に基づき当社が行った行為によりユーザーに生じた損害について一切の責任を負いません。
    </div>
    <div className="paragraph-title">第 12 条 保証の否認及び免責</div>
    <div className="default">
      1.
      当社は、パーティーの内容の適法性、ユーザーコンテンツの内容、ユーザーコンテンツの適法性その他配信に関する一切の事項につき如何なる保証も行うものではありません。本サービスは現状有姿で提供されるものであり、当社は本サービスについて、特定の目的への適合性、商業的有用性、完全性、継続性等を含め、一切保証を致しません。
      <br />
      2.
      ユーザーが当社から直接又は間接に、本サービス、当社ウェブサイト、本サービスの他のユーザーその他の事項に関する何らかの情報を得た場合であっても、当社はユーザーに対し本規約において規定されている内容を超えて如何なる保証も行うものではありません。
      <br />
      3.
      本サービスは、外部サービスと連携することがありますが、かかる連携を保証するものではなく、本サービスにおいて外部サービスと連携できなかった場合でも、当社は一切の責任を負いません。
      <br />
      4.
      本サービスが外部サービスと連携している場合において、ユーザーは外部利用規約を自己の費用と責任で遵守するものとし、ユーザーと当該外部サービスを運営する外部事業者との間で紛争等が生じた場合でも、当社は当該紛争等について一切の責任を負いません。
      <br />
      5.
      ユーザーは、本サービスを利用することが、ユーザーに適用のある法令、業界団体の内部規則等に違反するか否かを自己の責任と費用に基づいて調査するものとし、当社は、ユーザーによる本サービスの利用が、ユーザーに適用のある法令、業界団体の内部規則等に適合することを何ら保証するものではありません。
      <br />
      6.
      本サービス又は当社ウェブサイトに関連してユーザーと他のユーザー、外部事業者その他の第三者との間において生じた取引、連絡、紛争等については、ユーザーの責任において処理及び解決するものとし、当社はかかる事項について一切責任を負いません。
      <br />
      7.
      当社は、当社による本サービスの提供の中断、停止、終了、利用不能又は変更、ユーザーのメッセージ又は情報の削除又は消失､ユーザーの登録の取消、本サービスの利用によるデータの消失又は機器の故障若しくは損傷、その他本サービスに関連してユーザーが被った損害につき、賠償する責任を一切負わないものとします。
      <br />
      8.
      当社ウェブサイトから他のウェブサイトへのリンク又は他のウェブサイトから当社ウェブサイトへのリンクが提供されている場合でも、当社は、当社ウェブサイト以外のウェブサイト及びそこから得られる情報に関して如何なる理由に基づいても一切の責任を負わないものとします。
      <br />
      9.
      当社は、本サービスに関連してユーザーが被った損害について、一切賠償の責任を負いません。
    </div>
    <div className="paragraph-title">第 13 条 ユーザーの賠償等の責任</div>
    <div className="default">
      1.
      ユーザーは、本規約に違反することにより、又は本サービスの利用に関連して当社に損害を与えた場合、当社に対しその損害を賠償しなければなりません。
      <br />
      2.
      ユーザーが、本サービスに関連して他のユーザー、外部事業者その他の第三者からクレームを受け又はそれらの者との間で紛争を生じた場合には、直ちにその内
      容を当社に通知するとともに、ユーザーの費用と責任において当該クレーム又は紛争を処理し、当社からの要請に基づき、その経過及び結果を当社に報告するものとします。
      <br />
      3.
      ユーザーによる本サービスの利用に関連して、当社が、他のユーザー、外部事業者その他の第三者から権利侵害その他の理由により何らかの請求を受けた場合は、ユーザーは当該請求に基づき当社が当該第三者に支払いを余儀なくされた金額を賠償しなければなりません。
    </div>
    <div className="paragraph-title">第 14 条 有効期間</div>
    <div className="default">
      利用契約は、ユーザーについて第 3
      条に基づく登録が完了した日に効力が生じ、当該ユーザーの登録が取り消された日又は本サービスの提供が終了した日のいずれか早い日まで、当社とユーザーとの間で有効に存続するものとします。
    </div>
    <div className="paragraph-title">第 15 条 本規約等の変更 </div>
    <div className="default">
      1. 当社は、本サービスの内容を自由に変更できるものとします。
      <br />
      2.
      当社は、本規約（当社ウェブサイトに掲載する本サービスに関するルール、諸規定等を含みます。以下本項において同じ。）を変更できるものとします。当社は、本規約を変更した場合には、ユーザーに当該変更内容を通知するものとし、当該変更内容の通知後、ユーザーが本サービスを利用した場合又は当社の定める期間内に登録取消の手続をとらなかった場合には、ユーザーは、本規約の変更に同意したものとみなします。
    </div>
    <div className="paragraph-title">第 16 条 連絡/通知 </div>
    <div className="default">
      本サービスに関する問い合わせその他ユーザーから当社に対する連絡又は通知、及び本規約の変更に関する通知その他当社からユーザーに対する連絡又は通知は、当社の定める方法で行うものとします。当社からユーザーに対する連絡又は通知は、ユーザーの登録情報先に行った場合、ユーザーがこれを受領していなかったとしても通常到達すべき時に到達したものとみなします。
    </div>
    <div className="paragraph-title">第 17 条 本規約の譲渡等</div>
    <div className="default">
      .
      ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位又は本規約に基づく権利若しくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
      <br />
      2.
      当社は本サービスにかかる事業を第三者に譲渡（事業譲渡、会社分割その他態様の如何を問わないものとします。）した場合には、当該譲渡に伴い利用契約上の地位、本規約に基づく権利及び義務並びにユーザーの登録情報その他の顧客情報を当該譲渡の譲受人に譲渡することができるものとし、ユーザーは、かかる譲渡につき本項において予め同意したものとします。
    </div>
    <div className="paragraph-title">18 条 完全合意</div>
    <div className="default">
      本規約は、本規約に含まれる事項に関する当社とユーザーとの完全な合意を構成し、口頭又は書面を問わず、本規約に含まれる事項に関する当社とユーザーとの事前の合意、表明及び了解に優先します。
    </div>
    <div className="paragraph-title">第 19 条 分離可能性</div>
    <div className="default">
      本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有し、当社及びユーザーは、当該無効若しくは執行不能の条項又は部分を適法とし、執行力を持たせるために必要な範囲で修正し、当該無効若しくは執行不能な条項又は部分の趣旨並びに法律的及び経済的に同等の効果を確保できるように努めるものとします。
    </div>
    <div className="paragraph-title">第 20 条 裁判管轄 </div>
    <div className="default">
      本規約、本サービスの利用に関して紛争が生じた場合には、会員および当社は誠意をもって協議し、これを解決するものとします。協議によっても解決しない場合は、東京地方裁判所を第一審の専属的合意管轄裁判所とするものとします。
    </div>
    <div className="paragraph-title">第 21 条 準拠法</div>
    <div className="default">
      利用契約の成立、効力、解釈及び履行については、日本国法に準拠するものとします。
    </div>
  </div>
);

export default Terms;
