import { VFC } from 'react';

import 'styles/molecules/Stepper.scss';

type StepperProps = {
  /**
   * ステップ名
   */
  stepNames: string[];
  /**
   * カレントステップ
   */
  currentStep: number;
};

const Stepper: VFC<StepperProps> = ({
  stepNames = [
    '概要を決める',
    '会場を決める',
    'プランの選択',
    'パーティーを確定する',
  ],
  currentStep = 0,
}) => (
  <div className="stepper">
    {stepNames.map((stepName, i) => (
      <div
        className={`step ${i === currentStep ? 'current' : ''} ${
          i < currentStep ? 'done' : ''
        }`}
      >
        <div className="label">{stepName}</div>
        <div className="lines">
          <div className={`left-box${i === 0 ? ' hidden-box' : ''}`}>
            &nbsp;
          </div>
          <div
            className={`right-box${
              i === stepNames.length - 1 ? ' hidden-box' : ''
            }`}
          >
            &nbsp;
          </div>
          <div className="point">&nbsp;</div>
        </div>
      </div>
    ))}
  </div>
);

export default Stepper;
