import { VFC } from 'react';

import 'styles/organisms/AdminHeader.scss';

import Logo from 'components/organisms/Logo';
import RegisterInHeader from 'components/molecules/RegisterInHeader';

type TeaserHeaderProps = {
  /**
   * ロゴクリック時の関数
   */
  onLogoClicked: () => void;
  /**
   * 新規登録クリック時の関数
   */
  onRegisterClicked: () => void;
};

const TeaserHeader: VFC<TeaserHeaderProps> = ({
  onLogoClicked,
  onRegisterClicked,
}) => (
  <div id="admin-header">
    <div className="left-area">
      <Logo onItemClicked={onLogoClicked} type="header" place="admin" />
    </div>
    <div className="right-area">
      <RegisterInHeader onItemClicked={onRegisterClicked} />
    </div>
  </div>
);

export default TeaserHeader;
