import { VFC } from 'react';

import 'styles/organisms/RegisterStepperConfirm.scss';

import PageHeader from 'components/molecules/PageHeader';
import TextButton from 'components/atoms/TextButton';
import TextConfirm from 'components/atoms/TextConfirm';

import nextArrowIcon from 'images/icon_next_arrow.svg';
import backArrowIcon from 'images/icon_back_arrow.svg';

type Props = {
  /**
   * Currentかどうか
   */
  isCurrent?: boolean;
  /**
   * 戻るボタンクリック時
   */
  onBackClicked: () => void;
  /**
   * 登録ボタンクリック時
   */
  onConfirmClicked: () => void;
  /**
   * 入力済み項目
   */
  firstName: string;
  lastName: string;
  firstNameKana: string;
  lastNameKana: string;
  nickname: string;
  uniqueId: string;
  mail: string;
  pass: string;

  selectYear?: number;
  selectMonth?: number;
  selectDay?: number;
};

const RegisterStepperConfirm: VFC<Props> = ({
  isCurrent = false,
  onBackClicked,
  onConfirmClicked,
  firstName,
  lastName,
  firstNameKana,
  lastNameKana,
  nickname,
  uniqueId,
  mail,
  pass,
  selectYear,
  selectMonth,
  selectDay,
}) => (
  <div className={`register-steps ${isCurrent ? 'current' : ''}`}>
    <PageHeader label="登録内容の確認" />
    <div className="confirm-area">
      <TextConfirm title="メールアドレス" value={mail} />
      <TextConfirm title="パスワード" value={pass} type="password" />
      <TextConfirm title="ログインID" value={uniqueId} />
      <TextConfirm title="ニックネーム ※10文字以内" value={nickname} />
      <TextConfirm title="氏名" value={`${lastName} ${firstName}`} />
      <TextConfirm
        title="氏名（フリガナ）"
        value={`${lastNameKana} ${firstNameKana}`}
      />
      <TextConfirm
        title="誕生日"
        value={
          selectYear && selectMonth && selectDay
            ? `${selectYear?.toString()}年 ${selectMonth?.toString()}月 ${selectDay?.toString()}日`
            : ''
        }
      />
    </div>
    <div className="buttons">
      <TextButton
        label="戻る"
        onItemClicked={onBackClicked}
        leftIcon={backArrowIcon}
        variant="back"
      />
      <TextButton
        label="登録"
        onItemClicked={onConfirmClicked}
        rightIcon={nextArrowIcon}
      />
    </div>
  </div>
);

export default RegisterStepperConfirm;
