import { VFC } from 'react';

import 'styles/atoms/TextConfirm.scss';

type Props = {
  title: string;
  value: string;
  type?: 'default' | 'password';
};

const TextConfirm: VFC<Props> = ({ title, value, type = 'default' }) => (
  <div className="text-confirm">
    <div className="title">{title}</div>
    <div className="value">{type === 'password' ? '********' : value}</div>
  </div>
);

export default TextConfirm;
