import { VFC } from 'react';
import RoutePath from 'enums/RoutePath';
import { Route, Routes } from 'react-router-dom';

import DialogProvider from 'providers/DialogProvider';
import NotFoundPage from 'components/pages/NotFoundPage';
// import RegisterPage from 'containers/pages/RegisterPage';
// import RegisterCompletedPage from 'containers/pages/RegisterCompletedPage';
// import HomePage from 'containers/pages/HomePage';
import TermsPage from 'components/pages/TermsPage';
import PrivacyPolicyPage from 'components/pages/PrivacyPolicyPage';
import TeaserPage from 'containers/pages/TeaserPage';
import TeaserRegisterPage from 'components/pages/TeaserRegisterPage';
import TeaserRegisterCompletedPage from 'components/pages/TeaserRegisterCompletedPage';
import AppTermsPage from 'components/pages/AppTermsPage';
import RedirectTeaserRegisterPage from 'containers/pages/RedirectTeaserRegisterPage';
import RedirectTeaserRegisterCompletedPage from 'containers/pages/RedirectTeaserRegisterCompletedPage';
import AccountPage from 'containers/pages/AccountPage';
import AccountDeletePage from 'containers/pages/AccountDeletePage';
import AccountDeleteCompletedPage from 'containers/pages/AccountDeleteCompletedPage';
import SystemConsoleLogInPage from 'containers/pages/SystemConsoleLogInPage';

// TODO: 本番時にはVRMTestPageを削除する
const App: VFC = () => (
  <DialogProvider>
    <Routes>
      {/* <Route path={RoutePath.REGISTER} element={<RegisterPage />} />
      <Route
        path={RoutePath.REGISTER_COMPLETED}
        element={<RegisterCompletedPage />}
      /> */}
      <Route
        path={RoutePath.TEASER_REGISTER_COMPLETED}
        element={<TeaserRegisterCompletedPage />}
      />
      {/* <Route path={RoutePath.TOP} element={<HomePage />} /> */}
      <Route path={RoutePath.TEASER} element={<TeaserPage />} />
      <Route
        path={RoutePath.TEASER_REGISTER}
        element={<TeaserRegisterPage />}
      />
      <Route path={RoutePath.ACCOUNT} element={<AccountPage />} />
      <Route path={RoutePath.ACCOUNT_DELETE} element={<AccountDeletePage />} />
      <Route
        path={RoutePath.ACCOUNT_DELETE_COMPLETED}
        element={<AccountDeleteCompletedPage />}
      />
      <Route path={RoutePath.NOT_FOUND} element={<NotFoundPage />} />
      <Route path={RoutePath.TERMS} element={<TermsPage />} />
      <Route path={RoutePath.PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
      <Route path={RoutePath.APP_TERMS} element={<AppTermsPage />} />
      <Route
        path={RoutePath.REDIRECT_TEASER_REGISTER}
        element={<RedirectTeaserRegisterPage />}
      />
      <Route
        path={RoutePath.REDIRECT_TEASER_REGISTER_COMPLETED}
        element={<RedirectTeaserRegisterCompletedPage />}
      />
      <Route path="*" element={<NotFoundPage />} />
      <Route
        path={RoutePath.SYSTEM_CONSOLE_LOGIN}
        element={<SystemConsoleLogInPage />}
      />
    </Routes>
  </DialogProvider>
);

export default App;
