import { VFC } from 'react';
import SelectBirthday from 'components/organisms/SelectBirthday';

type Props = {
  /**
   * 年を選択した
   */
  onSelectYearChange: (value: string) => void;
  /**
   * 選択中の年
   */
  selectYear?: number;
  /**
   * 月を選択した
   */
  onSelectMonthChange: (value: string) => void;
  /**
   * 選択中の月
   */
  selectMonth?: number;
  /**
   * 日を選択した
   */
  onSelectDayChange: (value: string) => void;
  /**
   * 選択中の日
   */
  selectDay?: number;
  /**
   * Validate
   */
  isValidate?: boolean;
};

const EnhancedSelectBirthday: VFC<Props> = ({
  onSelectYearChange,
  selectYear,
  onSelectMonthChange,
  selectMonth,
  onSelectDayChange,
  selectDay,
  isValidate,
}) => {
  const years = [
    { value: 1920, key: '1920' },
    { value: 1921, key: '1921' },
    { value: 1922, key: '1922' },
    { value: 1923, key: '1923' },
    { value: 1924, key: '1924' },
    { value: 1925, key: '1925' },
    { value: 1926, key: '1926' },
    { value: 1927, key: '1927' },
    { value: 1928, key: '1928' },
    { value: 1929, key: '1929' },
    { value: 1930, key: '1930' },
    { value: 1931, key: '1931' },
    { value: 1932, key: '1932' },
    { value: 1933, key: '1933' },
    { value: 1934, key: '1934' },
    { value: 1935, key: '1935' },
    { value: 1936, key: '1936' },
    { value: 1937, key: '1937' },
    { value: 1938, key: '1938' },
    { value: 1939, key: '1939' },
    { value: 1940, key: '1940' },
    { value: 1941, key: '1941' },
    { value: 1942, key: '1942' },
    { value: 1943, key: '1943' },
    { value: 1944, key: '1944' },
    { value: 1945, key: '1945' },
    { value: 1946, key: '1946' },
    { value: 1947, key: '1947' },
    { value: 1948, key: '1948' },
    { value: 1949, key: '1949' },
    { value: 1950, key: '1950' },
    { value: 1951, key: '1951' },
    { value: 1952, key: '1952' },
    { value: 1953, key: '1953' },
    { value: 1954, key: '1954' },
    { value: 1955, key: '1955' },
    { value: 1956, key: '1956' },
    { value: 1957, key: '1957' },
    { value: 1958, key: '1958' },
    { value: 1959, key: '1959' },
    { value: 1960, key: '1960' },
    { value: 1961, key: '1961' },
    { value: 1962, key: '1962' },
    { value: 1963, key: '1963' },
    { value: 1964, key: '1964' },
    { value: 1965, key: '1965' },
    { value: 1966, key: '1966' },
    { value: 1967, key: '1967' },
    { value: 1968, key: '1968' },
    { value: 1969, key: '1969' },
    { value: 1970, key: '1970' },
    { value: 1971, key: '1971' },
    { value: 1972, key: '1972' },
    { value: 1973, key: '1973' },
    { value: 1974, key: '1974' },
    { value: 1975, key: '1975' },
    { value: 1976, key: '1976' },
    { value: 1977, key: '1977' },
    { value: 1978, key: '1978' },
    { value: 1979, key: '1979' },
    { value: 1980, key: '1980' },
    { value: 1981, key: '1981' },
    { value: 1982, key: '1982' },
    { value: 1983, key: '1983' },
    { value: 1984, key: '1984' },
    { value: 1985, key: '1985' },
    { value: 1986, key: '1986' },
    { value: 1987, key: '1987' },
    { value: 1988, key: '1988' },
    { value: 1989, key: '1989' },
    { value: 1990, key: '1990' },
    { value: 1991, key: '1991' },
    { value: 1992, key: '1992' },
    { value: 1993, key: '1993' },
    { value: 1994, key: '1994' },
    { value: 1995, key: '1995' },
    { value: 1996, key: '1996' },
    { value: 1997, key: '1997' },
    { value: 1998, key: '1998' },
    { value: 1999, key: '1999' },
    { value: 2000, key: '2000' },
    { value: 2001, key: '2001' },
    { value: 2002, key: '2002' },
    { value: 2003, key: '2003' },
    { value: 2004, key: '2004' },
    { value: 2005, key: '2005' },
    { value: 2006, key: '2006' },
    { value: 2007, key: '2007' },
    { value: 2008, key: '2008' },
    { value: 2009, key: '2009' },
    { value: 2010, key: '2010' },
    { value: 2011, key: '2011' },
    { value: 2012, key: '2012' },
    { value: 2013, key: '2013' },
    { value: 2014, key: '2014' },
    { value: 2015, key: '2015' },
    { value: 2016, key: '2016' },
    { value: 2017, key: '2017' },
    { value: 2018, key: '2018' },
    { value: 2019, key: '2019' },
    { value: 2020, key: '2020' },
    { value: 2021, key: '2021' },
  ];

  const months = [
    { value: 1, key: '1' },
    { value: 2, key: '2' },
    { value: 3, key: '3' },
    { value: 4, key: '4' },
    { value: 5, key: '5' },
    { value: 6, key: '6' },
    { value: 7, key: '7' },
    { value: 8, key: '8' },
    { value: 9, key: '9' },
    { value: 10, key: '10' },
    { value: 11, key: '11' },
    { value: 12, key: '12' },
  ];

  const days = [
    { value: 1, key: '1' },
    { value: 2, key: '2' },
    { value: 3, key: '3' },
    { value: 4, key: '4' },
    { value: 5, key: '5' },
    { value: 6, key: '6' },
    { value: 7, key: '7' },
    { value: 8, key: '8' },
    { value: 9, key: '9' },
    { value: 10, key: '10' },
    { value: 11, key: '11' },
    { value: 12, key: '12' },
    { value: 13, key: '13' },
    { value: 14, key: '14' },
    { value: 15, key: '15' },
    { value: 16, key: '16' },
    { value: 17, key: '17' },
    { value: 18, key: '18' },
    { value: 19, key: '19' },
    { value: 20, key: '20' },
    { value: 21, key: '21' },
    { value: 22, key: '22' },
    { value: 23, key: '23' },
    { value: 24, key: '24' },
    { value: 25, key: '25' },
    { value: 26, key: '26' },
    { value: 27, key: '27' },
    { value: 28, key: '28' },
    { value: 29, key: '29' },
    { value: 30, key: '30' },
    { value: 31, key: '31' },
  ];

  return (
    <SelectBirthday
      onSelectYearChange={onSelectYearChange}
      selectYear={selectYear}
      years={years}
      onSelectMonthChange={onSelectMonthChange}
      selectMonth={selectMonth}
      months={months}
      onSelectDayChange={onSelectDayChange}
      selectDay={selectDay}
      days={days}
      isValidate={isValidate}
    />
  );
};
export default EnhancedSelectBirthday;
