import { VFC } from 'react';

import 'styles/molecules/InputCodeInHeader.scss';

type Props = {
  /**
   * 説明テキスト
   */
  label?: string;
  /**
   * 有効かどうか
   */
  isEnabled?: boolean;
  /**
   * クリック時の関数
   */
  onItemClicked: () => void;
};

const InputCodeInHeader: VFC<Props> = ({
  label = '招待コード入力',
  isEnabled = true,
  onItemClicked = () => undefined,
}) => (
  <div className="input-code-in-header">
    <button
      type="button"
      className="input-code-button"
      onClick={onItemClicked}
      disabled={!isEnabled}
    >
      <p>{label}</p>
    </button>
  </div>
);

export default InputCodeInHeader;
