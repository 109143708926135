import { VFC } from 'react';

import 'styles/molecules/UsageGuideInHeader.scss';

import usageGuideImage from 'images/icon_header_usage_guide.svg';

type Props = {
  /**
   * 説明テキスト
   */
  alt?: string;
  /**
   * 有効かどうか
   */
  isEnabled?: boolean;
  /**
   * クリック時の関数
   */
  onItemClicked: () => void;
};

const UsageGuideInHeader: VFC<Props> = ({
  alt = '利用ガイド',
  isEnabled = true,
  onItemClicked = () => undefined,
}) => (
  <div className="usage-guide-in-header">
    <button
      type="button"
      className="usage-guide-button"
      onClick={onItemClicked}
      disabled={!isEnabled}
    >
      <img src={usageGuideImage} alt={alt} />
      <p>{alt}</p>
    </button>
  </div>
);

export default UsageGuideInHeader;
