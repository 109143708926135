import { VFC } from 'react';

import 'styles/molecules/BackButton.scss';

import backImage from 'images/icon_back.svg';
import ImageButton from 'components/atoms/ImageButton';

type IconButtonProps = {
  /**
   * 説明テキスト
   */
  alt: string;
  /**
   * Image Size
   */
  size: 'small' | 'medium' | 'large';
  /**
   * 有効かどうか
   */
  isEnabled?: boolean;
  /**
   * クリック時の関数
   */
  onItemClicked: () => void;
};

const IconButton: VFC<IconButtonProps> = ({
  alt = '',
  size = 'medium',
  isEnabled = true,
  onItemClicked = () => undefined,
}) => (
  <div className={`back-button ${size}`}>
    <ImageButton
      image={backImage}
      alt={alt}
      isEnabled={isEnabled}
      onItemClicked={onItemClicked}
    />
  </div>
);

export default IconButton;
