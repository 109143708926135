import { ReactNode, VFC } from 'react';
import { useNavigate } from 'react-router-dom';
import RoutePath from 'enums/RoutePath';
import TeaserLayout from 'components/layouts/TeaserLayout';

type Props = {
  children: ReactNode;
};
const EnhancedBasicLayout: VFC<Props> = ({ children }) => {
  const navigate = useNavigate();

  const handleRegisterClicked = () => {
    navigate(RoutePath.TEASER_REGISTER);
  };

  const handleLogoClicked = () => {
    navigate(RoutePath.TEASER);
  };

  return (
    <TeaserLayout onRegisterClicked={handleRegisterClicked} type="top" onLogoClicked={handleLogoClicked}>{children}</TeaserLayout>
  );
};

export default EnhancedBasicLayout;
