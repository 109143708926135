import { ReactNode, useCallback, useState, VFC } from 'react';
import MenuLayout from 'components/layouts/MenuLayout';
// import useCheckAccount from 'hooks/use-check-account';
// import useAuth from 'hooks/use-auth';
import { PageKey } from 'types/Page';

type Props = {
  children: ReactNode;
  currentPage: PageKey;
  isShowUsageGuide?: boolean;
  isShowLogin?: boolean;
  isShowRegister?: boolean;
  isShowInputCode?: boolean;
};
const EnhancedMenuLayout: VFC<Props> = ({
  children,
  currentPage = 'home',
  isShowUsageGuide = true,
  isShowLogin = false,
  isShowRegister = false,
  isShowInputCode = false,
}) => {
  // const { readAuthData } = useAuth();
  // const { checkAccount } = useCheckAccount();

  // const [isLoading, setIsLoading] = useState<boolean>(true);
  const [_isLogin, _setIsLogin] = useState<boolean>(false);

  // const checkLogin = useCallback(async () => {
  //   const authData = readAuthData();
  //   if (!authData) {
  //     // eslint-disable-next-line no-console
  //     console.log("can't get authData.");
  //     setIsLoading(false);

  //     return;
  //   }

  //   if (await checkAccount(authData, 'authorized', false)) {
  //     // eslint-disable-next-line no-console
  //     console.log('Login check OK.');
  //     _setIsLogin(true);
  //   }
  //   setIsLoading(false);
  // }, [checkAccount, readAuthData]);

  const [_isMenuOpen, _setIsMenuOpen] = useState<boolean>(false);

  const handleMenuIconClicked = useCallback(() => {
    _setIsMenuOpen(!_isMenuOpen);
  }, [_isMenuOpen]);

  // useEffect(() => {
  //   void checkLogin();
  // }, [checkLogin]);

  return (
    <div>
      {/* {!isLoading ? ( */}
      <div>
        <MenuLayout
          isLogin={_isLogin}
          isShowUsageGuide={isShowUsageGuide}
          isShowLogin={isShowLogin}
          isShowRegister={isShowRegister}
          isShowInputCode={isShowInputCode}
          isMenuOpen={_isMenuOpen}
          currentPage={currentPage}
          onMenuIconClicked={handleMenuIconClicked}
        >
          {children}
        </MenuLayout>
      </div>
      {/* ) : undefined} */}
    </div>
  );
};

export default EnhancedMenuLayout;
