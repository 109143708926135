import { VFC } from 'react';

import 'styles/pages/RegisterPage.scss';
import Stepper from 'components/molecules/Stepper';
import { TextFieldData } from 'types/TextFieldData';
import RegisterStepperInput from 'components/organisms/RegisterStepperInput';
import RegisterStepperConfirm from 'components/organisms/RegisterStepperConfirm';
import RegisterStepperSent from 'components/organisms/RegisterStepperSent';
import TeaserLayout from 'containers/layouts/TeaserLayout';
import AppLayout from 'containers/layouts/AppLayout';

type Props = {
  /**
   * forWebViewかどうか
   */
  forWebView?: boolean;
  /**
   * ティザーかどうか
   */
  isTeaser?: boolean;
  /**
   * ステップ名
   */
  stepNames: string[];
  /**
   * currentStep
   */
  currentStep: number;
  /**
   * 登録ボタンクリック時
   */
  onRegisterClicked: () => void;
  /**
   * 戻るボタンクリック時
   */
  onBackClicked: () => void;
  /**
   * 確認ボタンクリック時
   */
  onConfirmClicked: () => void;
  /**
   * 入力項目
   */
  firstNameData: TextFieldData;
  lastNameData: TextFieldData;
  firstNameKanaData: TextFieldData;
  lastNameKanaData: TextFieldData;
  nicknameData: TextFieldData;
  uniqueIdData: TextFieldData;
  mailData: TextFieldData;
  passData: TextFieldData;
  passConfirmData: TextFieldData;

  onSelectYearChange: (value: string) => void;
  selectYear?: number;
  onSelectMonthChange: (value: string) => void;
  selectMonth?: number;
  onSelectDayChange: (value: string) => void;
  selectDay?: number;
  isBirthdayValidate?: boolean;

  onTermsClicked: () => void;
  onPrivacyPolicyClicked: () => void;
  isTermsOpen?: boolean;
  isPrivacyPolicyOpen?: boolean;
};

const RegisterPage: VFC<Props> = ({
  forWebView,
  stepNames,
  currentStep,
  onRegisterClicked,
  onBackClicked,
  onConfirmClicked,
  firstNameData,
  lastNameData,
  firstNameKanaData,
  lastNameKanaData,
  nicknameData,
  uniqueIdData,
  mailData,
  passData,
  passConfirmData,
  onSelectYearChange,
  selectYear,
  onSelectMonthChange,
  selectMonth,
  onSelectDayChange,
  selectDay,
  isBirthdayValidate = true,
  onTermsClicked,
  onPrivacyPolicyClicked,
  isTermsOpen = false,
  isPrivacyPolicyOpen = false,
}) => (
  <div id="register-page">
    {forWebView ? (
      <AppLayout type="register" currentPage="teaser">
        <div id="register">
          <div className="stepper-area">
            <Stepper stepNames={stepNames} currentStep={currentStep} />
          </div>
          <RegisterStepperInput
            forWebView={forWebView}
            isCurrent={currentStep === 0}
            onRegisterClicked={onRegisterClicked}
            firstNameData={firstNameData}
            lastNameData={lastNameData}
            firstNameKanaData={firstNameKanaData}
            lastNameKanaData={lastNameKanaData}
            nicknameData={nicknameData}
            uniqueIdData={uniqueIdData}
            mailData={mailData}
            passData={passData}
            passConfirmData={passConfirmData}
            selectYear={selectYear}
            selectMonth={selectMonth}
            selectDay={selectDay}
            onSelectYearChange={onSelectYearChange}
            onSelectMonthChange={onSelectMonthChange}
            onSelectDayChange={onSelectDayChange}
            isBirthdayValidate={isBirthdayValidate}
            onTermsClicked={onTermsClicked}
            onPrivacyPolicyClicked={onPrivacyPolicyClicked}
            isTermsOpen={isTermsOpen}
            isPrivacyPolicyOpen={isPrivacyPolicyOpen}
          />
          <RegisterStepperConfirm
            isCurrent={currentStep === 1}
            onBackClicked={onBackClicked}
            onConfirmClicked={onConfirmClicked}
            firstName={firstNameData.value ? firstNameData.value : ''}
            lastName={lastNameData.value ? lastNameData.value : ''}
            firstNameKana={
              firstNameKanaData.value ? firstNameKanaData.value : ''
            }
            lastNameKana={lastNameKanaData.value ? lastNameKanaData.value : ''}
            nickname={nicknameData.value ? nicknameData.value : ''}
            uniqueId={uniqueIdData.value ? uniqueIdData.value : ''}
            mail={mailData.value ? mailData.value : ''}
            pass={passData.value ? passData.value : ''}
            selectYear={selectYear}
            selectMonth={selectMonth}
            selectDay={selectDay}
          />
          <RegisterStepperSent
            isCurrent={currentStep === 2}
            mail={mailData.value ? mailData.value : ''}
          />
        </div>
      </AppLayout>
    ) : (
      <TeaserLayout type="register">
        <div id="register">
          <div className="stepper-area">
            <Stepper stepNames={stepNames} currentStep={currentStep} />
          </div>
          <RegisterStepperInput
            isCurrent={currentStep === 0}
            onRegisterClicked={onRegisterClicked}
            firstNameData={firstNameData}
            lastNameData={lastNameData}
            firstNameKanaData={firstNameKanaData}
            lastNameKanaData={lastNameKanaData}
            nicknameData={nicknameData}
            uniqueIdData={uniqueIdData}
            mailData={mailData}
            passData={passData}
            passConfirmData={passConfirmData}
            selectYear={selectYear}
            selectMonth={selectMonth}
            selectDay={selectDay}
            onSelectYearChange={onSelectYearChange}
            onSelectMonthChange={onSelectMonthChange}
            onSelectDayChange={onSelectDayChange}
            isBirthdayValidate={isBirthdayValidate}
          />
          <RegisterStepperConfirm
            isCurrent={currentStep === 1}
            onBackClicked={onBackClicked}
            onConfirmClicked={onConfirmClicked}
            firstName={firstNameData.value ? firstNameData.value : ''}
            lastName={lastNameData.value ? lastNameData.value : ''}
            firstNameKana={
              firstNameKanaData.value ? firstNameKanaData.value : ''
            }
            lastNameKana={lastNameKanaData.value ? lastNameKanaData.value : ''}
            nickname={nicknameData.value ? nicknameData.value : ''}
            uniqueId={uniqueIdData.value ? uniqueIdData.value : ''}
            mail={mailData.value ? mailData.value : ''}
            pass={passData.value ? passData.value : ''}
            selectYear={selectYear || 1900}
            selectMonth={selectMonth || 0}
            selectDay={selectDay || 0}
          />
          <RegisterStepperSent
            isCurrent={currentStep === 2}
            mail={mailData.value ? mailData.value : ''}
          />
        </div>
      </TeaserLayout>
    )}
  </div>
);

export default RegisterPage;
