import { VFC } from 'react';

import 'styles/organisms/Logo.scss';

import logoImage from 'images/logo.svg';
import lpLogoImage from 'images/logo_lp.svg';
import logoFooterImage from 'images/logo_footer.svg';
import lpLogoFooterImage from 'images/logo_footer_lp.svg';
import ImageButton from 'components/atoms/ImageButton';

type LogoProps = {
  /**
   * 種類
   */
  type: 'header' | 'footer';
  /**
   * 場所
   */
  place: 'admin' | 'lp';
  /**
   * ボタンかどうか
   */
  isButton?: boolean;
  /**
   * 有効かどうか
   */
  isEnabled?: boolean;
  /**
   * クリック時の関数
   */
  onItemClicked: () => void;
};

const Logo: VFC<LogoProps> = ({
  type = 'header',
  place = 'admin',
  isButton = true,
  isEnabled = true,
  onItemClicked = () => undefined,
}) => (
  <div className={`logo ${type}-${place}`}>
    {isButton && place === 'admin' ? (
      <ImageButton
        image={type === 'header' ? logoImage : logoFooterImage}
        alt="logo"
        isEnabled={isEnabled}
        onItemClicked={onItemClicked}
      />
    ) : undefined}
    {isButton && place === 'lp' ? (
      <ImageButton
        image={type === 'header' ? lpLogoImage : lpLogoFooterImage}
        alt="logo"
        isEnabled={isEnabled}
        onItemClicked={onItemClicked}
      />
    ) : undefined}
    {!isButton && place === 'admin' ? (
      <div className={`logo-img ${type} ${isEnabled ? 'enabled' : 'disabled'}`}>
        <img src={type === 'header' ? logoImage : logoFooterImage} alt="logo" />
      </div>
    ) : undefined}
    {!isButton && place === 'lp' ? (
      <div className={`logo-img ${type} ${isEnabled ? 'enabled' : 'disabled'}`}>
        <img
          src={type === 'header' ? lpLogoImage : lpLogoFooterImage}
          alt="logo"
        />
      </div>
    ) : undefined}
  </div>
);

export default Logo;
