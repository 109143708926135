import { VFC } from 'react';

import 'styles/pages/AppTermsPage.scss';
import PageHeader from 'components/molecules/PageHeader';
import AppLayout from 'containers/layouts/AppLayout';
import Terms from 'components/organisms/Terms';

const AppTermsPage: VFC = () => (
  <div id="terms-page">
    <AppLayout currentPage="terms" type="app-terms">
      <div id="terms" className="common-page">
        <PageHeader label="ViRTH 利用規約" />
        <Terms />
      </div>
    </AppLayout>
  </div>
);

export default AppTermsPage;
