import { useEffect, VFC } from 'react';
import { useNavigate } from 'react-router-dom';
import RoutePath from 'enums/RoutePath';

const EnhancedTeaserRegisterPage: VFC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(RoutePath.TEASER_REGISTER);
  }, [navigate]);

  return <>&nbsp;</>;
};
export default EnhancedTeaserRegisterPage;
