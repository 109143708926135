import { VFC } from 'react';

import 'styles/pages/AccountDeletePage.scss';
import TextButton from 'components/atoms/TextButton';
import nextArrowIcon from 'images/icon_next_arrow.svg';
import PageHeader from 'components/molecules/PageHeader';
import TeaserLayout from 'containers/layouts/TeaserLayout';
import AppLayout from 'containers/layouts/AppLayout';

type Props = {
  onDeleteClicked: () => void;
  forWebView: boolean;
};

const AccountDeletePage: VFC<Props> = ({ onDeleteClicked, forWebView }) => (
  <div className="account-delete-area">
    {forWebView ? (
      <AppLayout type="accountDelete" currentPage="accountDelete">
        <div id="account-delete">
          <PageHeader label="ViRTHの退会" />
          <div className="text">
            退会処理が完了するとiUMアカウントが削除されます。
            <br />
            ViRTHおよび、他のサービス（meechoo、スゴヨセ）も
            <br />
            ご利用いただけなくなります。
          </div>
          <div className="button">
            <TextButton
              label="退会する"
              onItemClicked={onDeleteClicked}
              rightIcon={nextArrowIcon}
            />
          </div>
        </div>
      </AppLayout>
    ) : (
      <TeaserLayout type="accountDelete">
        <div id="account-delete">
          <PageHeader label="ViRTHの退会" />
          <div className="text">
            退会処理が完了するとiUMアカウントが削除されます。
            <br />
            ViRTHおよび、他のサービス（meechoo、スゴヨセ）も
            <br />
            ご利用いただけなくなります。
          </div>
          <div className="button">
            <TextButton
              label="退会する"
              onItemClicked={onDeleteClicked}
              rightIcon={nextArrowIcon}
            />
          </div>
        </div>
      </TeaserLayout>
    )}
  </div>
);

export default AccountDeletePage;
