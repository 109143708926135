import MenuButton from 'components/atoms/MenuButton';
import { VFC } from 'react';
import { Page, PageKey } from '../../types/Page';

import 'styles/organisms/Menu.scss';

type MenuProps = {
  isEnabled: boolean;
  isLogin: boolean;
  currentPage: PageKey;
  pagesWhenLogin?: Page[];
  pagesWhenLogout?: Page[];
  onItemClicked: (key: PageKey, path: string, link: string) => void;
};

const Menu: VFC<MenuProps> = ({
  isLogin,
  isEnabled,
  pagesWhenLogin = [],
  pagesWhenLogout = [],
  currentPage,
  onItemClicked,
}) => (
  <div className={`menu ${isEnabled ? 'enabled' : 'disabled'}`}>
    {isLogin &&
      pagesWhenLogin.map((item: Page, itemIndex: number) => (
        <MenuButton
          label={item.label}
          icon={item.icon}
          iconCurrent={item.iconCurrent}
          isEnabled={isEnabled}
          isCurrent={item.key === currentPage}
          onItemClicked={() =>
            onItemClicked(
              item.key,
              item.path ? item.path : '',
              item.link ? item.link : '',
            )
          }
          isParent={item.type === 'parent'}
          key={item.key ? item.key : itemIndex}
        />
      ))}
    {!isLogin &&
      pagesWhenLogout.map((item: Page, itemIndex: number) => (
        <MenuButton
          label={item.label}
          icon={item.icon}
          iconCurrent={item.iconCurrent}
          isEnabled={isEnabled}
          isCurrent={item.key === currentPage}
          onItemClicked={() =>
            onItemClicked(
              item.key,
              item.path ? item.path : '',
              item.link ? item.link : '',
            )
          }
          isParent={item.type === 'parent'}
          key={item.key ? item.key : itemIndex}
        />
      ))}
  </div>
);

export default Menu;
