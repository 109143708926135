const RoutePath = {
  // TOP: '/top',
  TEASER: '/',
  TEASER_REGISTER: '/register',
  TEASER_REGISTER_COMPLETED: '/register/completed',
  REDIRECT_TEASER_REGISTER: '/teaser_register',
  REDIRECT_TEASER_REGISTER_COMPLETED: '/teaser_register/completed',
  SYSTEM_CONSOLE_LOGIN: '/system/console/login',
  // DOWNLOAD: '/download',
  // ABOUT: '/about',
  // LOGIN: '/login', // TODO: ページなし
  // REGISTER: '/register',
  // REGISTER_COMPLETED: '/register/completed',
  // FAQ: '/faq',
  TERMS: '/terms',
  PRIVACY_POLICY: '/privacy_policy',
  // USAGE_GUIDE: '/usage_guide',
  // CREATE_PARTY: '/create_party',
  // CREATE_PARTY_COMPLETED: '/create_party/completed',
  // CREATE_PARTY_COMPLETED_ID: '/create_party/completed/:id',
  // CREATE_INVITATION: '/create_invitation',
  // CREATE_INVITATION_ID: '/create_invitation/:id',
  // CREATE_INVITATION_COMPLETED: '/create_invitation/completed',
  // CREATE_INVITATION_COMPLETED_ID: '/create_invitation/completed/:id',
  // PARTIES: '/parties',
  // PARTY_DETAIL: '/parties',
  // PARTY_DETAIL_ID: '/parties/:id',
  // PAST_PARTIES: '/past_parties',
  // PAST_PARTY_DETAIL: '/past_parties/',
  // PAST_PARTY_DETAIL_ID: '/past_parties/:id',
  // PARTIES_FLOWER_STAND_REGISTER: '/parties/flower_stands/register/',
  // PARTIES_FLOWER_STAND_REGISTER_ID: '/parties/flower_stands/register/:id',
  // PARTIES_FLOWER_STAND_REGISTER_COMPLETED:
  //   'parties/:id/flower_stands/register/completed',
  // PARTIES_FLOWER_STAND_REGISTER_COMPLETED_ID:
  //   'parties/:id/flower_stands/register/completed/:id',
  // AVATARS: '/avatars',
  // CREATE_AVATAR: '/avatars/create',
  // ACCOUNT: '/account',
  // FORGOT: '/forgot', // TODO: 機能ないはず
  ACCOUNT: '/account',
  ACCOUNT_DELETE: '/account/delete',
  ACCOUNT_DELETE_COMPLETED: '/account/delete/completed',
  APP_TERMS: '/app/terms',
  NOT_FOUND: '/not_found',
  // TEST: '/test',
  // VRM_TEST: '/vrm-test',
} as const;

export default RoutePath;
