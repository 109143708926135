import { VFC } from 'react';

import 'styles/pages/TermsPage.scss';
import TeaserLayout from 'containers/layouts/TeaserLayout';
import PageHeader from 'components/molecules/PageHeader';
import Terms from 'components/organisms/Terms';

const TermsPage: VFC = () => (
  <div id="terms-page">
    <TeaserLayout type="terms">
      <div id="terms" className="common-page">
        <PageHeader label="ViRTH 利用規約" />
        <Terms />
      </div>
    </TeaserLayout>
  </div>
);

export default TermsPage;
