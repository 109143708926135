import { VFC } from 'react';

import 'styles/organisms/RegisterStepperInput.scss';

import TextField from 'components/atoms/TextField';
import PageHeader from 'components/molecules/PageHeader';
import { Link } from 'react-router-dom';
import TextButton from 'components/atoms/TextButton';
import { TextFieldData } from 'types/TextFieldData';
import RoutePath from 'enums/RoutePath';
import Overlay from 'components/atoms/Overlay';
import SelectBirthday from '../../containers/organisms/SelectBirthday';
import Terms from './Terms';
import PrivacyPolicy from './PrivacyPolicy';

type Props = {
  /**
   * WebView用あどうか
   */
  forWebView?: boolean;
  /**
   * Currentかどうか
   */
  isCurrent?: boolean;
  /**
   * 新規登録ボタンクリック時
   */
  onRegisterClicked: () => void;
  /**
   * 入力項目
   */
  firstNameData: TextFieldData;
  lastNameData: TextFieldData;
  firstNameKanaData: TextFieldData;
  lastNameKanaData: TextFieldData;
  nicknameData: TextFieldData;
  uniqueIdData: TextFieldData;
  mailData: TextFieldData;
  passData: TextFieldData;
  passConfirmData: TextFieldData;

  onSelectYearChange: (value: string) => void;
  selectYear?: number;
  onSelectMonthChange: (value: string) => void;
  selectMonth?: number;
  onSelectDayChange: (value: string) => void;
  selectDay?: number;
  isBirthdayValidate?: boolean;

  onTermsClicked?: () => void;
  onPrivacyPolicyClicked?: () => void;
  isTermsOpen?: boolean;
  isPrivacyPolicyOpen?: boolean;
};

const RegisterStepperInput: VFC<Props> = ({
  forWebView = false,
  isCurrent = false,
  onRegisterClicked,
  firstNameData,
  lastNameData,
  firstNameKanaData,
  lastNameKanaData,
  nicknameData,
  uniqueIdData,
  mailData,
  passData,
  passConfirmData,
  onSelectYearChange,
  selectYear,
  onSelectMonthChange,
  selectMonth,
  onSelectDayChange,
  selectDay,
  isBirthdayValidate = true,
  onTermsClicked,
  onPrivacyPolicyClicked,
  isTermsOpen = false,
  isPrivacyPolicyOpen = false,
}) => (
  <div className={`register-steps ${isCurrent ? 'current' : ''}`}>
    <div id="inner-terms" className="common-page">
      <Overlay
        onItemClicked={onTermsClicked || (() => undefined)}
        isOpen={isTermsOpen}
        forWebView={forWebView}
      >
        <PageHeader label="利用規約" />
        <Terms />
        <TextButton
          label="閉じる"
          variant="minimal-underline"
          customClassName="bottom-close-button"
          onItemClicked={onTermsClicked || (() => undefined)}
        />
      </Overlay>
    </div>
    <div id="inner-privacy-policy" className="common-page">
      <Overlay
        onItemClicked={onPrivacyPolicyClicked || (() => undefined)}
        isOpen={isPrivacyPolicyOpen}
        forWebView={forWebView}
      >
        <PageHeader label="プライバシーポリシー" />
        <PrivacyPolicy forWebView />
        <TextButton
          label="閉じる"
          variant="minimal-underline"
          customClassName="bottom-close-button"
          onItemClicked={onPrivacyPolicyClicked || (() => undefined)}
        />
      </Overlay>
    </div>
    <PageHeader label="新規会員登録" />
    <div className="input-area">
      <div className="attention-required">
        <span>*</span>は必須項目です。
      </div>
      <TextField
        placeholder={mailData.placeholder}
        fieldName={mailData.fieldName}
        fieldNameSub={mailData.fieldNameSub}
        isShowFieldName={mailData.isShowFieldName}
        isShowRequiredMark={mailData.isShowRequiredMark}
        inputRef={mailData.inputRef}
        onChange={(event) =>
          mailData.onChange ? mailData.onChange(event) : undefined
        }
        maxLength={mailData.maxLength}
        validate={mailData.validate}
        validateText={mailData.validateText}
        key="email"
      />
      <TextField
        type={passData.type}
        placeholder={passData.placeholder}
        fieldName={passData.fieldName}
        fieldNameSub={passData.fieldNameSub}
        isShowFieldName={passData.isShowFieldName}
        isShowRequiredMark={passData.isShowRequiredMark}
        inputRef={passData.inputRef}
        onChange={(event) =>
          passData.onChange ? passData.onChange(event) : undefined
        }
        maxLength={passData.maxLength}
        validate={passData.validate}
        validateText={passData.validateText}
        key="pass"
      />
      <TextField
        type={passConfirmData.type}
        placeholder={passConfirmData.placeholder}
        fieldName={passConfirmData.fieldName}
        fieldNameSub={passConfirmData.fieldNameSub}
        isShowFieldName={passConfirmData.isShowFieldName}
        isShowRequiredMark={passConfirmData.isShowRequiredMark}
        inputRef={passConfirmData.inputRef}
        onChange={(event) =>
          passConfirmData.onChange ? passConfirmData.onChange(event) : undefined
        }
        maxLength={passConfirmData.maxLength}
        validate={passConfirmData.validate}
        validateText={passConfirmData.validateText}
        key="passConfirm"
      />
      <div className="name-fields">
        <div className="field-name">
          氏名<span className="required">*</span>{' '}
          <span className="warning">※アプリ内では表示されません</span>
        </div>
        <div className="name-set">
          <TextField
            placeholder={lastNameData.placeholder}
            fieldName={lastNameData.fieldName}
            fieldNameSub={lastNameData.fieldNameSub}
            isShowFieldName={lastNameData.isShowFieldName}
            isShowRequiredMark={lastNameData.isShowRequiredMark}
            inputRef={lastNameData.inputRef}
            onChange={(event) =>
              lastNameData.onChange ? lastNameData.onChange(event) : undefined
            }
            maxLength={lastNameData.maxLength}
            validate={lastNameData.validate}
            validateText={lastNameData.validateText}
            key="lastName"
          />
          <TextField
            placeholder={firstNameData.placeholder}
            fieldName={firstNameData.fieldName}
            fieldNameSub={firstNameData.fieldNameSub}
            isShowFieldName={firstNameData.isShowFieldName}
            isShowRequiredMark={firstNameData.isShowRequiredMark}
            inputRef={
              firstNameData.inputRef ? firstNameData.inputRef : undefined
            }
            onChange={(event) =>
              firstNameData.onChange ? firstNameData.onChange(event) : undefined
            }
            maxLength={firstNameData.maxLength}
            validate={firstNameData.validate}
            validateText={firstNameData.validateText}
            key="firstName"
          />
        </div>
      </div>
      <div className="name-fields">
        <div className="field-name">
          氏名（フリガナ）<span className="required">*</span>
        </div>
        <div className="name-set">
          <TextField
            placeholder={lastNameKanaData.placeholder}
            fieldName={lastNameKanaData.fieldName}
            fieldNameSub={lastNameKanaData.fieldNameSub}
            isShowFieldName={lastNameKanaData.isShowFieldName}
            isShowRequiredMark={lastNameKanaData.isShowRequiredMark}
            inputRef={lastNameKanaData.inputRef}
            onChange={(event) =>
              lastNameKanaData.onChange
                ? lastNameKanaData.onChange(event)
                : undefined
            }
            maxLength={lastNameKanaData.maxLength}
            validate={lastNameKanaData.validate}
            validateText={lastNameKanaData.validateText}
            key="lastName"
          />
          <TextField
            placeholder={firstNameKanaData.placeholder}
            fieldName={firstNameKanaData.fieldName}
            fieldNameSub={firstNameKanaData.fieldNameSub}
            isShowFieldName={firstNameKanaData.isShowFieldName}
            isShowRequiredMark={firstNameKanaData.isShowRequiredMark}
            inputRef={
              firstNameKanaData.inputRef
                ? firstNameKanaData.inputRef
                : undefined
            }
            onChange={(event) =>
              firstNameKanaData.onChange
                ? firstNameKanaData.onChange(event)
                : undefined
            }
            maxLength={firstNameKanaData.maxLength}
            validate={firstNameKanaData.validate}
            validateText={firstNameKanaData.validateText}
            key="firstName"
          />
        </div>
      </div>
      <TextField
        placeholder={uniqueIdData.placeholder}
        fieldName={uniqueIdData.fieldName}
        fieldNameSub={uniqueIdData.fieldNameSub}
        isShowFieldName={uniqueIdData.isShowFieldName}
        isShowRequiredMark={uniqueIdData.isShowRequiredMark}
        inputRef={uniqueIdData.inputRef}
        onChange={(event) =>
          uniqueIdData.onChange ? uniqueIdData.onChange(event) : undefined
        }
        maxLength={uniqueIdData.maxLength}
        // minLength={uniqueIdData.minLength}
        validate={uniqueIdData.validate}
        validateText={uniqueIdData.validateText}
        key="uniqueId"
      />
      <TextField
        placeholder={nicknameData.placeholder}
        fieldName={nicknameData.fieldName}
        fieldNameSub={nicknameData.fieldNameSub}
        isShowFieldName={nicknameData.isShowFieldName}
        isShowRequiredMark={nicknameData.isShowRequiredMark}
        inputRef={nicknameData.inputRef}
        onChange={(event) =>
          nicknameData.onChange ? nicknameData.onChange(event) : undefined
        }
        maxLength={nicknameData.maxLength}
        validate={nicknameData.validate}
        validateText={nicknameData.validateText}
        key="nickname"
      />

      <SelectBirthday
        onSelectYearChange={onSelectYearChange}
        selectYear={selectYear}
        onSelectMonthChange={onSelectMonthChange}
        selectMonth={selectMonth}
        onSelectDayChange={onSelectDayChange}
        selectDay={selectDay}
        isValidate={isBirthdayValidate}
      />

      {forWebView ? (
        <div className="terms">
          「登録」をクリックすることで、当サイト
          <TextButton
            label="利用規約"
            variant="link"
            customClassName="link-button"
            onItemClicked={onTermsClicked}
          />
          に同意するものとします。ViRTHは
          <TextButton
            label="プライバシーポリシー"
            variant="link"
            customClassName="link-button"
            onItemClicked={onPrivacyPolicyClicked}
          />
          にしたがってお客様の情報を取り扱います。
        </div>
      ) : (
        <div className="terms">
          「登録」をクリックすることで、当サイト
          <Link target="_blank" to={RoutePath.TERMS}>
            利用規約
          </Link>
          に同意するものとします。ViRTHは
          <Link target="_blank" to={RoutePath.PRIVACY_POLICY}>
            プライバシーポリシー
          </Link>
          にしたがってお客様の情報を取り扱います。
        </div>
      )}
    </div>
    <div className="register-button">
      <TextButton label="登録" onItemClicked={onRegisterClicked} />
    </div>
  </div>
);

export default RegisterStepperInput;
