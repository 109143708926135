import IconButton from 'components/molecules/IconButton';
import { RefObject, VFC } from 'react';

import 'styles/organisms/InvitationCodeInputOverlay.scss';

import iconCloseImage from 'images/icon_close.svg';
import TextButton from 'components/atoms/TextButton';
import TextField from 'components/atoms/TextField';

type InvitationCodeInputOverlayProps = {
  /**
   * 表示するかどうか
   */
  isOpen?: boolean;
  /**
   * クローズ時の処理
   */
  onClose: () => void;
  /**
   * 確定ボタンクリック時
   */
  onFixClicked: () => void;
  /**
   * タイトル
   */
  title?: string;
  /**
   * placeholder
   */
  placeholder?: string;
  /**
   * 最大文字数
   */
  maxLength?: number;
  /**
   * inputRef
   */
  inputRef: RefObject<HTMLInputElement>;
};

const InvitationCodeInputOverlay: VFC<InvitationCodeInputOverlayProps> = ({
  isOpen = false,
  onClose,
  onFixClicked,
  title = '招待コードを入力してください',
  placeholder = '',
  maxLength = 24,
  inputRef,
}) => (
  <>
    {isOpen ? (
      <div className="overlay invitation-code-input-overlay">
        <div className="overlay-block">
          <input type="button" onClick={onClose} />
        </div>
        <div className="overlay-inner">
          <div className="close-button">
            <IconButton
              onItemClicked={onClose}
              alt="X"
              size="small"
              image={iconCloseImage}
            />
          </div>
          <div className="text-input-area">
            <div className="header-title">{title}</div>
            <TextField
              placeholder={placeholder}
              customClassName="input-field"
              maxLength={maxLength}
              inputRef={inputRef}
            />
            <div className="fix-button">
              <TextButton label="決定" onItemClicked={onFixClicked} />
            </div>
          </div>
        </div>
      </div>
    ) : undefined}
    {undefined}
  </>
);

export default InvitationCodeInputOverlay;
