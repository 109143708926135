// import TextButton from 'components/atoms/TextButton';
import COPYRIGHT from 'constants/Copyright';
import { VFC } from 'react';

import 'styles/organisms/AdminFooter.scss';

const AdminFooter: VFC = () => (
  <div id="admin-footer">
    <div id="copyright">{COPYRIGHT}</div>
  </div>
);

export default AdminFooter;
