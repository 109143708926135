const getBaseAccountIumAPIUrl = (): string => {
  const { hostname } = window.location;

  if (hostname.startsWith('www.virth.jp') || hostname.startsWith('virth.jp')) {
    return 'https://account.ium.co.jp/api';
  }

  return 'https://stg-account.ium.co.jp/api';
};

export default getBaseAccountIumAPIUrl;
