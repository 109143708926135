import { ReactNode, VFC } from 'react';

import 'styles/atoms/Overlay.scss';

import closeOverlayIcon from 'images/icon_close_overlay.svg';

type Props = {
  /**
   * WebView8日どうか
   */
  forWebView?: boolean;
  /**
   * メニューを開いているかどうか
   */
  isOpen?: boolean;
  /**
   * クリック時の関数
   */
  onItemClicked: () => void;
  /**
   * 子要素
   */
  children: ReactNode;
  /**
   * type
   */
  type?: 'default' | 'minimum';
};

const Overlay: VFC<Props> = ({
  forWebView = false,
  isOpen = false,
  onItemClicked = () => undefined,
  children,
  type = 'default',
}) => (
  <div
    className={`overlay-area${isOpen ? ' open' : ''}${
      type === 'minimum' ? ' minimum' : ''
    }${forWebView ? ' webview' : ''}`}
  >
    <div className="overlay-layer" />
    <div className="close-button-layer">
      <button type="button" className="close-button" onClick={onItemClicked}>
        <img alt="menu" src={isOpen ? closeOverlayIcon : undefined} />
      </button>
    </div>
    <div className="overlay-inner">{children}</div>
  </div>
);

export default Overlay;
