import Response from 'core/Response';
import axios from 'axios';
import iUMAPI from 'api/ium/api';
import ErrorResponse from 'core/ErrorResponse';

type RequestBody = {
  name: string;
  uniqueId: string;
  email: string;
  password: string;
  // avatar?: string;
};

type ResponseData = {
  id: string;
  name: string;
  uniqueId: string;
  email: string;
  // avatar?: string;
};

const post = async (body: RequestBody): Promise<Response<ResponseData>> => {
  try {
    const response = await iUMAPI.post(`v1/users`, body);

    return {
      success: true,
      data: response.data as ResponseData,
      status: response.status,
      statusText: response.statusText,
    };
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw new Error('API Calling Error: This is not Axios Error.');
    }

    return {
      success: false,
      data: {} as ResponseData,
      status: error.response?.status,
      statusText: error.message,
      errorData: error.response?.data as ErrorResponse,
    };
  }
};

export default post;
