import { useCallback, useContext } from 'react';
import { AlertMessageSetting } from 'types/AlertMessagesSetting';
import { ErrorMessageSetting } from 'types/ErrorMessagesSetting';
import DialogContext from 'contexts/DialogContext';

const useShowAlert = (): {
  showErrorAlert: (setting: ErrorMessageSetting) => void;
  showAlert: (setting: AlertMessageSetting) => void;
} => {
  const { showDialog } = useContext(DialogContext);
  const showErrorAlert = useCallback(
    (setting: ErrorMessageSetting) => {
      const statusStr = setting.status || '';

      showDialog(
        setting.target,
        setting.subText,
        `STATUS_CODE:${statusStr} ERROR_CODE:${setting.errorCode}`,
      );

      // eslint-disable-next-line no-console
      console.log(
        `[Error] ${setting.target} : ${setting.subText} STATUS_CODE:${statusStr} ERROR_CODE:${setting.errorCode}`,
      );
    },
    [showDialog],
  );

  const showAlert = useCallback(
    (setting: AlertMessageSetting) => {
      showDialog(setting.title, setting.text, setting.subText);
    },
    [showDialog],
  );

  return {
    showErrorAlert,
    showAlert,
  };
};

export default useShowAlert;
