import { VFC } from 'react';

import 'styles/organisms/PrivacyPolicy.scss';

type Props = {
  forWebView?: boolean;
};

const PrivacyPolicy: VFC<Props> = ({ forWebView = false }) => (
  <div id="common-document-format">
    <div className="default">
      ViRTH（以下「本サービス」という）を運営する株式会社kiCk（以下、｢当社｣といいます。）は、ユーザーの皆さまが安心して本サービスをご利用頂けるよう、プライバシーポリシー（個人情報保護に関する基本方針）を定め個人情報保護に取り組んでいます。
      <br />
      本プライバシーポリシーは、本サービスをご利用いただく際に提供いただく、お客様の氏名・住所・生年月日・性別・電話番号・Eメールアドレス・クレジットカード番号・商品送付先などの情報（お客様より供される第三者の住所・氏名などの情報を含みます。以下、個人情報といいます）に適用されます。
      なお、本サービス利用規約において、本プライバシーポリシーと異なる定めが明示されている場合、利用規約が優先的に適用されるものとします。
    </div>
    <div className="paragraph-title">1.個人情報の定義</div>
    <div className="default">
      本プライバシーポリシーにおいて、個人情報とは、個人情報保護法第 2 条第 1
      項により定義される個人情報を意味するものとします。
    </div>
    <div className="paragraph-title">2.個人情報の適正な取得</div>
    <div className="default">
      当社は、以下の方法で個人情報を取得します。
      <br />
      1.ユーザーから直接、個人情報の提供を受ける方法
      <br />
      2.ユーザーが当社サービスを利用する際に、自動的に個人情報を記録する方法
      <br />
      3.第三者から間接的にユーザーの個人情報の提供を受ける方法
      <br />
      4.刊行物やインターネット等で公開された個人情報を取得する方法。
    </div>
    <div className="paragraph-title">3.個人情報の利用目的</div>
    <div className="default">
      当社は、個人情報を以下の目的で利用いたします。
      <br />
      1.「ViRTH」サービスの提供のため
      <br />
      2.「ViRTH」サービスに関するご案内、お問い合せ等への対応のため
      <br />
      3.当社の商品、サービス等のご案内、サービス提供、情報提供、広告配信のため
      <br />
      4.「ViRTH」サービスに関する当社の規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため
      <br />
      5.「ViRTH」サービスに関する規約等の変更などを通知するため
      <br />
      6.当社のサービスの改善、新サービスの開発等に役立てるため
      <br />
      7.当社のサービスに関連して、個人を識別できない形式に加工した統計データを作成するため
      <br />
      8.個人を識別できない形式に加工したデータを共同研究のための当社から大学・研究機関へ提供するため
      <br />
      9.ユーザーの個人認証・本人確認・審査するため
      <br />
      10.その他、上記利用目的に付随する目的のため
    </div>
    <div className="paragraph-title">4.個人情報利用目的の変更</div>
    <div className="default">
      当社は、個人情報の利用目的を関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合には個人情報の主体である個人（以下「本人」といいます。）に通知し又は公表します。
    </div>
    <div className="paragraph-title">5.個人情報利用の制限</div>
    <div className="default">
      当社は、個人情報保護法その他の法令により許容される場合を除き、本人の同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を取り扱いません。但し、次の場合はこの限りではありません。
      <br />
      1.法令に基づく場合
      <br />
      2.人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
      <br />
      3.公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
      <br />
      4.国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
    </div>
    <div className="paragraph-title">6.個人情報の安全管理</div>
    <div className="default">
      当社は、個人情報の紛失、破壊、改ざん及び漏洩などのリスクに対して、個人情報の安全管理が図られるよう、当社の従業員に対し、必要かつ適切な監督を行います。また、当社は、個人情報の取扱いの全部又は一部を委託する場合は、委託先において個人情報の安全管理が図られるよう、必要かつ適切な監督を行います。
    </div>
    <div className="paragraph-title">7.第三者提供</div>
    <div className="default">
      7.1
      当社は、第4項各号のいずれかに該当する場合を除くほか、あらかじめ本人の同意を得ないで、個人情報を第三者に提供しません。但し、次に掲げる場合は上記に定める第三者への提供には該当しません。
      <br />
      1.当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託することに伴って個人情報を提供する場合
      <br />
      2.合併その他の事由による事業の承継に伴って個人情報が提供される場合
      <br />
      3.個人情報保護法の定めに基づき共同利用する場合
      <br />
      7.2 第 7.1 項の定めにかかわらず、当社は、第 5
      項各号のいずれかに該当する場合を除くほか、外国（個人情報保護法第 24
      条に基づき個人情報保護委員会規則で指定される国を除きます。）にある第三者（個人情報保護法第
      24
      条に基づき個人情報保護委員会規則で指定される基準に適合する体制を整備している者を除きます。）に個人情報を提供する場合には、あらかじめ外国にある第三者への提供を認める旨の本人の同意を得るものとします。
      <br />
      7.3 当社は、個人情報を第三者に提供したときは、個人情報保護法第 25
      条に従い、記録の作成及び保存を行います。
      <br />
      7.4 当社は、第三者から個人情報の提供を受ける場合には、個人情報保護法第 26
      条に従い、必要な確認を行い、当該確認にかかる記録の作成及び保存を行うものとします。
    </div>
    <div className="paragraph-title">8.個人情報の訂正等</div>
    <div className="default">
      当社は、本人から、個人情報が真実でないという理由によって、個人情報保護法の定めに基づきその内容の訂正、追加又は削除（以下「訂正等」といいます。）を求められた場合には、本人ご自身からのご請求であることを確認の上で、利用目的の達成に必要な範囲内において、遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正等を行い、その旨を本人に通知します（訂正等を行わない旨の決定をしたときは、本人に対しその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当社が訂正等の義務を負わない場合は、この限りではありません。
    </div>
    <div className="paragraph-title">9.個人情報の利用停止等</div>
    <div className="default">
      当社は、本人から、本人の個人情報が、あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由又は偽りその他不正の手段により取得されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止又は消去（以下「利用停止等」といいます。）を求められた場合、又は個人情報がご本人の同意なく第三者に提供されているという理由により、個人情報保護法の定めに基づきその提供の停止（以下「提供停止」といいます。）を求められた場合において、そのご請求に理由があることが判明した場合には、本人ご自身からのご請求であることを確認の上で、遅滞なく個人情報の利用停止等又は提供停止を行い、その旨を本人に通知します。但し、個人情報保護法その他の法令により、当社が利用停止等又は提供停止の義務を負わない場合は、この限りではありません。
    </div>
    <div className="paragraph-title">10. プライバシーポリシーの変更</div>
    <div className="default">
      当社は、法令等の定めがある場合を除き、プライバシーポリシーを随時変更することができるものとします。
    </div>
    <div className="paragraph-title">
      11. 個人情報保護マネジメントシステムの継続的改善
    </div>
    <div className="default">
      個人情報保護マネジメントシステムの継続的改善 当社の事業内容の変化、
      及び、事業を取り巻く法令、社会環境、 IT 環境の変化等に対応して
      『個人情報保護マネジメントシステム』を継続的に見直し、改善します。
    </div>
    <div className="paragraph-title">12. 個人情報苦情及び相談窓口</div>
    <div className="default">
      株式会社kiCk
      <br />
      〒105-0003
      <br />
      東京都港区赤坂 8-5-32 田中駒ビル 8 階
      <br />
      TEL：03-6434-7844／FAX：03-6434-7218
      <br />
      {forWebView ? (
        <div className="email">e-mail： info@virth.jp</div>
      ) : (
        <div className="email">
          e-mail： <a href="mailto:info@virth.jp">info@virth.jp</a>
        </div>
      )}
      <br />
      （受付時間：平日 10:00～16:00）
      <br />
      <br />
      制定日：2022 年 10 月 21 日<br />
      株式会社 kiCk
      <br />
      代表取締役 藤川 大詩
    </div>
  </div>
);

export default PrivacyPolicy;
