import { useState, useCallback, ReactNode, VFC, useMemo } from 'react';
import CustomDialog from 'components/molecules/CustomDialog';
import DialogContext from 'contexts/DialogContext';

type Props = {
  children: ReactNode;
};

const DialogProvider: VFC<Props> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState<string>('');
  const [text, setText] = useState<string>('');
  const [subText, setSubText] = useState<string>('');
  const showDialog = useCallback(
    (newTitle: string, newText: string, newSubText: string) => {
      setTitle(newTitle);
      setText(newText);
      setSubText(newSubText);
      setOpen(true);
    },
    [],
  );
  const hideDialog = useCallback(() => setOpen(false), []);
  const dialogFunctions = useMemo(
    () => ({ showDialog, hideDialog }),
    [showDialog, hideDialog],
  );

  return (
    <DialogContext.Provider value={dialogFunctions}>
      {children}
      <CustomDialog
        isOpen={open}
        title={title}
        text={text}
        subtext={subText}
        needCloseButton
        onCloseButtonClick={hideDialog}
      />
    </DialogContext.Provider>
  );
};

export default DialogProvider;
