import { createContext } from 'react';

type IDialogContext = {
  showDialog: (title: string, text: string, subtext: string) => void;
  hideDialog: () => void;
};

const DialogContext = createContext<IDialogContext>({} as IDialogContext);

export default DialogContext;
