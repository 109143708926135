import Response from 'core/Response';
import axios from 'axios';
import iUMAPI from 'api/account-ium/api';
import { AuthData } from 'types/AuthData';

type ResponseData = {
  userIdDB: number;
  userId: string;
  firstName: string;
  lastName: string;
  furiganaLastName: string;
  furiganaFirstName: string;
  nickname: string;
  birthday: string;
  email: string;
  avatar?: string;
};

const get = async (authData: AuthData): Promise<Response<ResponseData>> => {
  try {
    const response = await iUMAPI.get(`user-info`, {
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${authData.token}`,
      },
    });

    return {
      success: true,
      data: response.data as ResponseData,
      status: response.status,
      statusText: response.statusText,
    };
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw new Error('API Calling Error: This is not Axios Error.');
    }

    return {
      success: false,
      data: {} as ResponseData,
      status: error.response?.status,
      statusText: error.message,
    };
  }
};

export default get;
