import ErrorMessages from 'constants/ErrorMessages';
import { useCallback } from 'react';
import { AuthData } from 'types/AuthData';
// import loginAPI from 'api/account-ium/login/post';
// import refreshAPI from 'api/account-ium/token-refresh/post';
// import ErrorMessages from 'constants/ErrorMessages';
// import RoutePath from 'enums/RoutePath';
// import { useNavigate } from 'react-router-dom';
import useLocalStorage from './use-localStorage';
import useShowAlert from './use-show-alert';

const useAuth = (): {
  readAuthData: () => AuthData | null;
  systemConsoleLogin: (id: string, password: string) => boolean;
  logout: () => void;
  // refresh: () => Promise<boolean>;
  saveTokenFromPath: (token: string) => void;
} => {
  const { save, read } = useLocalStorage();
  const { showErrorAlert } = useShowAlert();
  // const navigate = useNavigate();

  // const reloadChangeLoginStatus = useCallback(() => {
  //   if (window.location.pathname === '/') {
  //     window.location.reload();
  //   } else {
  //     navigate(RoutePath.TOP);
  //   }
  // }, [navigate]);

  const saveAccessToken = useCallback(
    (token: string, tokenType: string, expiresIn: number) => {
      save('token', token);
      save('tokenType', tokenType);
      save('expiresIn', expiresIn.toString());
    },
    [save],
  );

  const readAuthData = useCallback(() => {
    const token = read('token');
    const tokenType = read('tokenType');
    const expiresIn = read('expiresIn');

    return {
      token,
      tokenType,
      expiresIn: expiresIn ? parseInt(expiresIn, 10) : 0,
    } as AuthData;
  }, [read]);

  const systemConsoleLogin = useCallback(
    (id: string, password: string) => {
      const type = true;
      if (type) {
        showErrorAlert(ErrorMessages.LOGIN_FAILD);

        if (id === password) {
          // eslint-disable-next-line no-console
          console.log('id equals password.');
        }

        return false;
      }

      return false;
    },
    [showErrorAlert],
  );

  // const login = useCallback(
  //   async (id: string, password: string) => {
  //     // RESET
  //     save('isLack_nickname', '');
  //     save('isLack_birthday', '');
  //     save('isLack_firstName', '');
  //     save('isLack_lastName', '');
  //     save('isLack_furiganaFirstName', '');
  //     save('isLack_furiganaLastName', '');

  //     const { success, data, status } = await loginAPI({
  //       id,
  //       password,
  //     });
  //     if (success && data) {
  //       saveAccessToken(data.token, data.tokenType, data.expiresIn);

  //       // // eslint-disable-next-line no-console
  //       // console.log(data);

  //       if (data.lackInfo.length > 0) {
  //         // eslint-disable-next-line no-console
  //         console.log('exist lackInfo');

  //         // const lackInfoDummy = [
  //         //   'nickname',
  //         //   // 'birthday',
  //         //   // 'firstName',
  //         //   // 'lastName',
  //         //   // 'furiganaFirstName',
  //         //   // 'furiganaLastName',
  //         // ];

  //         data.lackInfo.forEach((item) => {
  //           save(`isLack_${item}`, 'true');
  //         });
  //         // lackInfoDummy.forEach((item) => save(`isLack_${item}`, 'true'));

  //         // update関数で追加情報を登録すると同時に、利用規約も同意させるため
  //         save('tempId', id);
  //         save('tempPass', password);

  //         navigate(RoutePath.REGISTER_ADDITIONAL);

  //         return false;
  //       }
  //       if (data.privacyPolicyAgree === 0) {
  //         save('tempId', id);
  //         save('tempPass', password);
  //         navigate(RoutePath.UPDATE_PRIVACY_POLICY);

  //         return false;
  //       }

  //       reloadChangeLoginStatus();

  //       return true;
  //     }
  //     // eslint-disable-next-line no-console
  //     console.log(`login failed.${status ? status.toString() : ''}`);
  //     showErrorAlert(ErrorMessages.LOGIN_FAILD);

  //     return false;
  //   },
  //   [navigate, reloadChangeLoginStatus, save, saveAccessToken, showErrorAlert],
  // );

  const logout = useCallback(() => {
    saveAccessToken('', '', 0);
    // reloadChangeLoginStatus();
  }, [saveAccessToken]);

  // const refresh = useCallback(async () => {
  //   const authData = readAuthData();
  //   const { success, data, status } = await refreshAPI({
  //     token: authData.token,
  //   });
  //   if (success && data) {
  //     saveAccessToken(data.token, data.tokenType, data.expiresIn);

  //     return true;
  //   }
  //   // eslint-disable-next-line no-console
  //   console.log(`login failed.${status ? status.toString() : ''}`);
  //   showErrorAlert(ErrorMessages.REFRESH_TOKEN_DISABLED);

  //   return false;
  // }, [readAuthData, saveAccessToken, showErrorAlert]);

  const saveTokenFromPath = useCallback(
    (token: string) => {
      saveAccessToken(token, 'bearer', 3600);
    },
    [saveAccessToken],
  );

  return {
    readAuthData,
    systemConsoleLogin,
    logout,
    // refresh,
    saveTokenFromPath,
  };
};

export default useAuth;
