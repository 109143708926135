import { VFC } from 'react';

import 'styles/atoms/MenuButton.scss';

type MenuButtonProps = {
  /**
   * ラベル
   */
  label: string;
  /**
   * 現在選択されているかどうか
   */
  isCurrent?: boolean;
  /**
   * アイテムクリック時に呼ばれる関数
   */
  onItemClicked: (path: string) => void;
  /**
   * currentアイコン画像のパス
   * ※importされたコンポーネントを渡す
   */
  iconCurrent?: string;
  /**
   * アイコン画像のパス
   * ※importされたコンポーネントを渡す
   */
  icon?: string;
  /**
   * パス
   */
  path?: string;
  /**
   * 有効かどうか
   */
  isEnabled?: boolean;
  /**
   * 親かどうか
   */
  isParent?: boolean;
};

const MenuButton: VFC<MenuButtonProps> = ({
  label,
  isCurrent = false,
  onItemClicked,
  iconCurrent = '',
  icon = '',
  path = '',
  isEnabled = true,
  isParent = true,
}) => (
  <div className="menu-button">
    <button
      type="button"
      className={`${isParent ? 'parent' : 'child'} ${
        isEnabled ? '' : 'disabled'
      } ${isCurrent ? 'current' : ''}`}
      onClick={() => onItemClicked(path)}
      disabled={!isEnabled}
    >
      {icon ? (
        <img src={isCurrent ? iconCurrent : icon} alt={label} />
      ) : undefined}
      {label}
    </button>
  </div>
);

export default MenuButton;
