import { VFC } from 'react';

import 'styles/pages/PrivacyPolicyPage.scss';
import TeaserLayout from 'containers/layouts/TeaserLayout';
import PageHeader from 'components/molecules/PageHeader';
import PrivacyPolicy from 'components/organisms/PrivacyPolicy';

const PrivacyPolicyPage: VFC = () => (
  <div id="privacy-policy-page">
    <TeaserLayout type="privacy-policy">
      <div id="privacy-policy" className="common-page">
        <PageHeader label="プライバシーポリシー" />
        <PrivacyPolicy />
      </div>
    </TeaserLayout>
  </div>
);

export default PrivacyPolicyPage;
