import { createRef, ReactNode, VFC } from 'react';
import AdminFooter from 'components/organisms/AdminFooter';
import AdminHeader from 'components/organisms/AdminHeader';

import 'styles/layouts/AppLayout.scss';
import { PageKey } from 'types/Page';
import Overlay from 'components/atoms/Overlay';
import TextButton from 'components/atoms/TextButton';
import PageHeader from 'components/molecules/PageHeader';
import PrivacyPolicy from 'components/organisms/PrivacyPolicy';

type Props = {
  children: ReactNode;
  currentPage: PageKey;
  /**
   * type
   */
  type:
    | 'top'
    | 'privacy-policy'
    | 'terms'
    | 'app-terms'
    | 'register'
    | 'account'
    | 'accountDelete';
  onPrivacyPolicyClicked?: () => void;
  isPrivacyPolicyOpen?: boolean;
};

const AppLayout: VFC<Props> = ({
  children,
  currentPage,
  type,
  onPrivacyPolicyClicked,
  isPrivacyPolicyOpen = false,
}) => (
  <div id="app-layout">
    <div className="app-layout-inner">
      <AdminHeader
        isShowUsageGuide={false}
        isShowLogin={false}
        isShowRegister={false}
        isShowInputCode={false}
        isShowMenuButton={false}
        isMenuOpened={false}
        onLogoClicked={() => undefined}
        onMenuIconClicked={() => undefined}
        onUsageGuideClicked={() => undefined}
        onLoginClicked={() => undefined}
        onRegisterClicked={() => undefined}
        onInputCodeClicked={() => undefined}
        isCodeInputOverlayOpen={false}
        onCodeInputOverlayClose={() => undefined}
        onCodeInputFixOverlay={() => undefined}
        codeInputRef={createRef<HTMLInputElement>()}
      />
      <div id="content" className={currentPage}>
        {children}
      </div>
      <div id="inner-privacy-policy" className="common-page">
        <Overlay
          onItemClicked={onPrivacyPolicyClicked || (() => undefined)}
          isOpen={isPrivacyPolicyOpen}
        >
          <PageHeader label="プライバシーポリシー" />
          <PrivacyPolicy forWebView />
          <TextButton
            label="閉じる"
            variant="minimal-underline"
            customClassName="bottom-close-button"
            onItemClicked={onPrivacyPolicyClicked || (() => undefined)}
          />
        </Overlay>
      </div>
      {type !== 'privacy-policy' && type !== 'app-terms' ? (
        <div className="privacy-policy">
          <TextButton
            label="プライバシーポリシー"
            variant="link"
            customClassName="link-button"
            onItemClicked={onPrivacyPolicyClicked}
          />
        </div>
      ) : undefined}
      <AdminFooter />
    </div>
  </div>
);

export default AppLayout;
