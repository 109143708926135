import { VFC } from 'react';
import ButtonVariant from 'types/ButtonVariant';

import 'styles/atoms/TextButton.scss';

type TextButtonProps = {
  /**
   * ラベル
   */
  label: string;
  /**
   * variant
   */
  variant?: ButtonVariant;
  /**
   * アイテムクリック時に呼ばれる関数
   */
  onItemClicked?: () => void;
  /**
   * アイコン画像のパス
   * ※importされたコンポーネントを渡す
   * TODO: 選択式に変更予定
   */
  icon?: string;
  /**
   * 左側のアイコン
   */
  leftIcon?: string;
  /**
   * 右側のアイコン
   */
  rightIcon?: string;
  /**
   * 有効かどうか
   */
  isEnabled?: boolean;
  /**
   * クラス名
   * デフォルトでは空
   * クラス名をカスタマイズしたい場合に指定する
   */
  customClassName?: string;
};

const TextButton: VFC<TextButtonProps> = ({
  label,
  variant = 'fill',
  onItemClicked,
  icon = '',
  leftIcon = '',
  rightIcon = '',
  isEnabled = true,
  customClassName = '',
}) => (
  <div
    className={
      customClassName ? `text-button ${customClassName}` : 'text-button'
    }
  >
    <button
      type="button"
      className={`${variant} ${isEnabled ? '' : 'disabled'}`}
      onClick={onItemClicked}
      disabled={!isEnabled}
    >
      {icon ? <img src={icon} alt={label} /> : undefined}
      {leftIcon ? (
        <img className="left-icon" src={leftIcon} alt={label} />
      ) : undefined}
      {rightIcon ? (
        <img className="right-icon" src={rightIcon} alt={label} />
      ) : undefined}
      {label}
    </button>
  </div>
);

export default TextButton;
