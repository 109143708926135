import { ReactNode, VFC } from 'react';
import AdminFooter from 'components/organisms/AdminFooter';
import TeaserHeader from 'components/organisms/TeaserHeader';

import 'styles/layouts/TeaserLayout.scss';
import { Link } from 'react-router-dom';
import RoutePath from 'enums/RoutePath';

type Props = {
  children: ReactNode;
  /**
   * ロゴボタンクリック時
   */
  onLogoClicked: () => void;
  /**
   * 登録ボタンクリック時
   */
  onRegisterClicked: () => void;
  /**
   * type
   */
  type:
    | 'top'
    | 'privacy-policy'
    | 'terms'
    | 'register'
    | 'account'
    | 'accountDelete';
};

const TeaserLayout: VFC<Props> = ({
  children,
  onLogoClicked,
  onRegisterClicked,
  type = 'top',
}) => (
  <div id="teaser-layout">
    <div className="teaser-layout-inner">
      <TeaserHeader
        onLogoClicked={onLogoClicked}
        onRegisterClicked={onRegisterClicked}
      />
      <div id="content" className={type}>
        {children}
      </div>
      {type !== 'privacy-policy' ? (
        <div className="privacy-policy">
          <Link target="_blank" to={RoutePath.PRIVACY_POLICY}>
            プライバシーポリシー
          </Link>
        </div>
      ) : undefined}
      <AdminFooter />
    </div>
  </div>
);

export default TeaserLayout;
