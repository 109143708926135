import { ReactNode, VFC } from 'react';
import AdminFooter from 'components/organisms/AdminFooter';
// import AdminHeader from 'containers/organisms/AdminHeader';

import 'styles/layouts/MenuLayout.scss';
import Menu from 'containers/organisms/Menu';
import { PageKey } from 'types/Page';

type Props = {
  children: ReactNode;
  isShowUsageGuide?: boolean;
  isShowLogin?: boolean;
  isShowRegister?: boolean;
  isShowInputCode?: boolean;
  isMenuOpen: boolean;
  isLogin?: boolean;
  isEnabled?: boolean;
  currentPage: PageKey;
  onMenuIconClicked: () => void;
  onFooterItemClicked?: (link: string) => void;
};

const MenuLayout: VFC<Props> = ({
  children,
  // isShowUsageGuide = true,
  // isShowLogin = false,
  // isShowRegister = false,
  // isShowInputCode = false,
  isMenuOpen = false,
  isLogin = false,
  isEnabled = true,
  currentPage,
  // onMenuIconClicked,
}) => (
  <div id="menu-layout">
    <div className="menu-layout-inner">
      <div className={`menu-area${isMenuOpen ? ' isOpen' : ''}`}>
        <Menu
          isLogin={isLogin}
          isEnabled={isEnabled}
          currentPage={currentPage}
        />
        <div className="menu-spacer-footer">&nbsp;</div>
      </div>
      {/* <AdminHeader
        isShowUsageGuide={isShowUsageGuide}
        isShowLogin={isShowLogin}
        isShowRegister={isShowRegister}
        isShowInputCode={isShowInputCode}
        isMenuOpen={isMenuOpen}
        onMenuIconClicked={onMenuIconClicked}
      /> */}
      <div id="content">{children}</div>
      <AdminFooter />
    </div>
  </div>
);

export default MenuLayout;
