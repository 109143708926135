import { VFC } from 'react';

import 'styles/pages/AccountPage.scss';
import MenuLayout from 'containers/layouts/MenuLayout';
import PageHeader from 'components/molecules/PageHeader';
import { AccountData } from 'types/AccountData';
import EditPropertyItem from 'components/organisms/EditPropertyItem';
import AccountEditType from 'types/AccountEditType';
import { Link } from 'react-router-dom';
import AppLayout from 'containers/layouts/AppLayout';

type Props = {
  accountInfo: AccountData;
  onClick: (type: AccountEditType) => void;
  forWebView: boolean;
  deleteLinkUrl: string;
};

const AccountPage: VFC<Props> = ({
  accountInfo,
  forWebView,
  deleteLinkUrl,
}) => (
  <div className="account-area">
    {forWebView ? (
      <AppLayout currentPage="account" type="account">
        <div id="account">
          <PageHeader label="ユーザー情報" />
          <div className="confirm-area">
            <EditPropertyItem
              title="氏名"
              value={`${accountInfo.lastName} ${accountInfo.firstName}`}
              onClick={() => undefined}
              isShowButton={false}
            />
            <EditPropertyItem
              title="氏名（フリガナ）"
              value={`${accountInfo.furiganaLastName} ${accountInfo.furiganaFirstName}`}
              onClick={() => undefined}
              isShowButton={false}
            />
            <EditPropertyItem
              title="メールアドレス"
              value={accountInfo.email}
              onClick={() => undefined}
              isShowButton={false}
            />
            <EditPropertyItem
              title="パスワード"
              value="******"
              type="password"
              onClick={() => undefined}
              isShowButton={false}
            />
            <EditPropertyItem
              title="ログインID"
              value={accountInfo.userId}
              onClick={() => undefined}
              isShowButton={false}
            />
            <EditPropertyItem
              title="ニックネーム "
              value={accountInfo.nickname}
              onClick={() => undefined}
              isShowButton={false}
            />
            <EditPropertyItem
              title="生年月日"
              value={`${
                accountInfo.birthday
                  ? accountInfo.birthday.substring(0, 4)
                  : '----'
              } 年 ${
                accountInfo.birthday
                  ? accountInfo.birthday.substring(5, 7)
                  : '--'
              } 月 ${
                accountInfo.birthday
                  ? accountInfo.birthday.substring(8, 10)
                  : '--'
              } 日`}
              onClick={() => undefined}
              isShowButton={false}
            />
          </div>

          <div className="account-delete-link">
            ViRTHの退会は<Link to={deleteLinkUrl}>こちら</Link>
          </div>
        </div>
      </AppLayout>
    ) : (
      <MenuLayout currentPage="account" isShowInputCode>
        <div id="account">
          <PageHeader label="ユーザー情報" />
          <div className="confirm-area">
            <EditPropertyItem
              title="氏名"
              value={`${accountInfo.lastName} ${accountInfo.firstName}`}
              onClick={() => undefined}
            />
            <EditPropertyItem
              title="氏名（フリガナ）"
              value={`${accountInfo.furiganaLastName} ${accountInfo.furiganaFirstName}`}
              onClick={() => undefined}
            />
            <EditPropertyItem
              title="メールアドレス"
              value={accountInfo.email}
              onClick={() => undefined}
            />
            <EditPropertyItem
              title="パスワード"
              value="******"
              type="password"
              onClick={() => undefined}
            />
            <EditPropertyItem
              title="ログインID"
              value={accountInfo.userId}
              onClick={() => undefined}
            />
            <EditPropertyItem
              title="ニックネーム "
              value={accountInfo.nickname}
              onClick={() => undefined}
            />
            <EditPropertyItem
              title="生年月日"
              value={`${
                accountInfo.birthday
                  ? accountInfo.birthday.substring(0, 4)
                  : '----'
              } 年 ${
                accountInfo.birthday
                  ? accountInfo.birthday.substring(5, 7)
                  : '--'
              } 月 ${
                accountInfo.birthday
                  ? accountInfo.birthday.substring(8, 10)
                  : '--'
              } 日`}
              onClick={() => undefined}
            />
          </div>

          <div className="account-delete-link">
            ViRTHの退会は
            <Link to={deleteLinkUrl}>こちら</Link>
          </div>
        </div>
      </MenuLayout>
    )}
  </div>
);

export default AccountPage;
