import { VFC } from 'react';

import 'styles/pages/TeaserPage.scss';
import TeaserLayout from 'containers/layouts/TeaserLayout';
import TextButton from 'components/atoms/TextButton';
import MoviePlayButton from 'components/atoms/MoviePlayButton';
import Overlay from 'components/atoms/Overlay';

import imageLogo from 'images/logo.svg';
import imageVirthTxt from 'images/lp/virth_txt.svg';
import nextArrowIcon from 'images/icon_next_arrow.svg';
import nextArrowPinkIcon from 'images/icon_next_arrow._pink.svg';
// import titleFieatures from 'images/lp/title_features.svg';
// import titleWorld from 'images/lp/title_world.svg';
// import titleDecoration from 'images/lp/title_decoration.svg';
// import titleAvatar from 'images/lp/title_avatar.svg';
// import titleDramatic from 'images/lp/title_dramatic.svg';
// import titleSurprise from 'images/lp/title_surprise.svg';
// import titleExciting from 'images/lp/title_exciting.svg';
// import titleEasyStart from 'images/lp/title_easy_start.svg';
// import titleMultiDevice from 'images/lp/title_multi_device.svg';
// import titleRentalService from 'images/lp/title_rental_service.svg';
// import titleTieUpService from 'images/lp/title_tie_up_service.svg';
import titleFieatures from 'images/lp/title_features.png';
import titleWorld from 'images/lp/title_world.png';
import titleDecoration from 'images/lp/title_decoration.png';
import titleAvatar from 'images/lp/title_avatar.png';
import titleDramatic from 'images/lp/title_dramatic.png';
import titleSurprise from 'images/lp/title_surprise.png';
import titleExciting from 'images/lp/title_exciting.png';
import titleEasyStart from 'images/lp/title_easy_start.png';
import titleMultiDevice from 'images/lp/title_multi_device.png';
import titleRentalService from 'images/lp/title_rental_service.png';
import titleTieUpService from 'images/lp/title_tie_up_service.png';

import imageFeatures from 'images/lp/image_features.png';
import imageWorld from 'images/lp/image_world.png';
import imageDecoration from 'images/lp/image_decoration.png';
import imageAvatar from 'images/lp/image_avatar.png';
import imagePoint2 from 'images/lp/image_point2.png';
import imageDramatic from 'images/lp/image_dramatic.png';
import imageSurprise from 'images/lp/image_surprise.png';
import imageExciting from 'images/lp/image_exciting.png';
import imagePoint3 from 'images/lp/image_point3.png';
// import imageMultiDevice from 'images/lp/image_multi_device.svg';
import imageMultiDevice from 'images/lp/image_multi_device.png';
import imageRentalService from 'images/lp/image_rental_service.png';
import imageTieUpService from 'images/lp/image_tie_up_service.png';

// import imageHeadPoint1 from 'images/lp/head_point1.svg';
// import imageHeadPoint2 from 'images/lp/head_point2.svg';
// import imageHeadPoint3 from 'images/lp/head_point3.svg';
import imageHeadPoint1 from 'images/lp/head_point1.png';
import imageHeadPoint2 from 'images/lp/head_point2.png';
import imageHeadPoint3 from 'images/lp/head_point3.png';

import imageStep01 from 'images/lp/step01.svg';
import imageStep02 from 'images/lp/step02.svg';
import imageStep03 from 'images/lp/step03.svg';
import imageStep04 from 'images/lp/step04.svg';
import imageStepItem01 from 'images/lp/step_item01.png';
import imageStepItem02 from 'images/lp/step_item02.png';
import imageStepItem03 from 'images/lp/step_item03.png';
import imageStepItem04 from 'images/lp/step_item04.png';
import imageFooterVisual from 'images/lp/footer_visual.png';

type Props = {
  /**
   * 登録ボタンクリック時
   */
  onRegisterClicked: () => void;
  /**
   * メールボタンクリック時
   */
  onMailClicked: () => void;
  /**
   * 動画ボタンクリック時
   */
  onMovieClicked: () => void;
  /**
   * 動画を閉じるボタン
   */
  onMovieCloseClicked: () => void;
  /**
   * 動画オーバーレイを開いているかどうか
   */
  isOverlayShow: boolean;
  /**
   * 動画パスの配列
   */
  movies?: string[];
};

const TeaserPage: VFC<Props> = ({
  onRegisterClicked,
  onMailClicked,
  onMovieClicked,
  onMovieCloseClicked,
  isOverlayShow,
  movies = [],
}) => (
  <TeaserLayout type="top">
    <Overlay
      onItemClicked={onMovieCloseClicked}
      isOpen={isOverlayShow}
      type="minimum"
    >
      <div className="overlay-content">
        {movies.map((movie) => (
          <iframe
            width="560"
            height="315"
            // src="https://www.youtube.com/embed/_3EuiU1qdpE"
            src={movie}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        ))}
      </div>
    </Overlay>

    <div id="teaser">
      <div id="keyvisual">
        <div className="title-logo">
          <img src={imageLogo} alt="ViRTH β版" />
        </div>
        <div className="title-text">
          <img
            src={imageVirthTxt}
            alt="世界初！お祝いパーティー特化型メタバース空間[ヴァース]"
          />
        </div>
        <div className="register-button">
          <TextButton
            label="事前登録受付中！"
            variant="rounded"
            onItemClicked={onRegisterClicked}
            rightIcon={nextArrowIcon}
          />
        </div>
        <div className="devices">
          <div className="title">対応デバイス</div>
          <div className="device">
            Meta Quest 2<span> | </span>Windows<span> | </span>iOS / Android
          </div>
        </div>
        {/* <div className="annnouce-box">
          <div className="title">β版アプリローンチ延期のお知らせ</div>
          <div className="text">
            機能の不具合によりViRTHアプリのリリースを
            <br />
            延期させていただきます。
            <br />
            リリースまでしばらくお待ちください。
          </div>
        </div> */}
      </div>
      <div id="about">
        <div className="lead">
          創造性と感動に満ちた、
          <br />
          かつてない体験をしよう！
        </div>
        <div className="lead-sub">
          お祝いパーティーに完全特化した
          <br />
          メタバース空間「ViRTH」。
          <br />
          日本の街を貸し切って、
          <br />
          最高のパーティーを楽しもう！
        </div>
        <div className="movie-area">
          <MoviePlayButton onMovieClicked={onMovieClicked} />
        </div>
      </div>
      <div id="features">
        <div className="title">
          <img src={titleFieatures} alt="Features" />
          <div className="subtitle">
            <div className="line" />
            <div className="text">ViRTHの特徴</div>
          </div>
        </div>
        <div className="visual">
          <img src={imageFeatures} alt="features" />
        </div>
        <div className="point type1">
          <div className="lead-area">
            <div className="point-img">
              <img src={imageHeadPoint1} alt="Point1" />
            </div>
            <div className="lead">
              <div className="title">
                街をまるごとぜんぶ！
                <br />
                貸し切りパーティー開催！
              </div>
              <div className="explain">
                日本の街を、パーティー会場に！
                <br />
                事前デコレーションや、オリジナルアバターなど、
                <br />
                他にはない、独自の世界を満喫しよう！
              </div>
            </div>
          </div>
          <div className="item">
            <div className="title">
              <img src={titleWorld} alt="World" />
            </div>
            <div className="subtitle">ViRTHの世界</div>
            <div className="image">
              <img src={imageWorld} alt="world" />
            </div>
            <div className="explain">
              <div className="line" />
              <div className="text">
                ソフビみたいな、
                <br />
                独自の世界で非日常の体験を！
              </div>
            </div>
          </div>
          <div className="item">
            <div className="title">
              <img src={titleDecoration} alt="Decoration" />
            </div>
            <div className="subtitle">デコレーション</div>
            <div className="image">
              <img src={imageDecoration} alt="decoration" />
            </div>
            <div className="explain">
              <div className="line" />
              <div className="text">
                事前にデコってパーティーを
                <br />
                もっと華やかに！
              </div>
            </div>
          </div>
          <div className="item">
            <div className="title">
              <img src={titleAvatar} alt="Avatar" />
            </div>
            <div className="subtitle">アバター</div>
            <div className="image">
              <img src={imageAvatar} alt="avatar" />
            </div>
            <div className="explain">
              <div className="line" />
              <div className="text">
                テクスチャを変えて、自分好みの
                <br />
                オリジナルアバターをつくろう！
              </div>
            </div>
          </div>
        </div>
        <div className="visual">
          <img src={imagePoint2} alt="point2" />
        </div>
        <div className="point type2">
          <div className="lead-area">
            <div className="point-img">
              <img src={imageHeadPoint2} alt="Point2" />
            </div>
            <div className="lead">
              <div className="title">
                感動的でVR的。
                <br />
                イベントが盛りだくさん！
              </div>
              <div className="explain">
                メタバースならではの印象的な演出の数々！
                <br />
                主役も参加者も一緒になって、
                <br />
                忘れられない、ここだけの体験を楽しもう！
              </div>
            </div>
          </div>

          <div className="item">
            <div className="title">
              <img src={titleDramatic} alt="Dramatic" />
            </div>
            <div className="subtitle">ドラマチック</div>
            <div className="image">
              <img src={imageDramatic} alt="dramatic" />
            </div>
            <div className="explain">
              <div className="line" />
              <div className="text">
                ここでしか味わえない、
                <br />
                特別な時間を。
              </div>
            </div>
          </div>
          <div className="item">
            <div className="title">
              <img src={titleSurprise} alt="Surprise" />
            </div>
            <div className="subtitle">サプライズ</div>
            <div className="image">
              <img src={imageSurprise} alt="surprise" />
            </div>
            <div className="explain">
              <div className="line" />
              <div className="text">
                主役も驚く、
                <br />
                サプライズ演出をご用意！
              </div>
            </div>
          </div>
          <div className="item">
            <div className="title">
              <img src={titleExciting} alt="Exciting" />
            </div>
            <div className="subtitle">エキサイティング</div>
            <div className="image">
              <img src={imageExciting} alt="exciting" />
            </div>
            <div className="explain">
              <div className="line" />
              <div className="text">
                VRならではのアクティベーションで、
                <br />
                さらに盛り上がる！
              </div>
            </div>
          </div>
        </div>
        <div className="visual">
          <img src={imagePoint3} alt="point3" />
        </div>
        <div className="point type3">
          <div className="lead-area">
            <div className="point-img">
              <img src={imageHeadPoint3} alt="Point3" />
            </div>
            <div className="lead">
              <div className="title">
                いつでも、どこでも、
                <br />
                誰とでも！
              </div>
              <div className="explain">
                メタバースを、とことん楽しんでもらいたいから、
                <br />
                さまざまな環境に柔軟に対応しています！
                <br />
                デバイスのレンタルサービスも！
              </div>
            </div>
          </div>

          <div className="item">
            <div className="title">
              <img src={titleEasyStart} alt="Easy Start" />
            </div>
            <div className="subtitle">はじめ方はカンタン</div>

            <div className="explain">
              <div className="line" />
              <div className="text">カンタンステップで、気軽に開催！</div>
            </div>
            <div className="steps">
              <div className="hr-box" />
              <div className="step">
                <div className="left-box">
                  <img src={imageStep01} alt="Step01" />
                </div>
                <div className="right">
                  <div className="inner-left">パーティーを登録しよう！</div>
                  <div className="inner-right step01">
                    <img src={imageStepItem01} alt="icon" />
                  </div>
                </div>
              </div>
              <div className="step">
                <div className="left-box">
                  <img src={imageStep02} alt="Step02" />
                </div>
                <div className="right">
                  <div className="inner-left">
                    ゲスト(主役・招待客)を
                    <br />
                    招待しよう！
                  </div>
                  <div className="inner-right step02">
                    <img src={imageStepItem02} alt="icon" />
                  </div>
                </div>
              </div>
              <div className="step">
                <div className="left-box">
                  <img src={imageStep03} alt="Step03" />
                </div>
                <div className="right">
                  <div className="inner-left">事前に会場をデコろう！</div>
                  <div className="inner-right step03">
                    <img src={imageStepItem03} alt="icon" />
                  </div>
                </div>
              </div>
              <div className="step">
                <div className="left-box">
                  <img src={imageStep04} alt="Step04" />
                </div>
                <div className="right">
                  <div className="inner-left">みんなで感動を楽しもう！</div>
                  <div className="inner-right  step04">
                    <img src={imageStepItem04} alt="icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="title">
              <img src={titleMultiDevice} alt="Multi Device" />
            </div>
            <div className="subtitle">マルチデバイス</div>
            <div className="image">
              <img src={imageMultiDevice} alt="Multi Device" />
            </div>
            <div className="explain">
              <div className="line" />
              <div className="text">さまざまなデバイスに、マルチ対応！</div>
            </div>
          </div>
          <div className="item">
            <div className="title">
              <img src={titleRentalService} alt="Rental Service" />
            </div>
            <div className="subtitle">レンタルサービス</div>
            <div className="image">
              <img src={imageRentalService} alt="Rental Service" />
            </div>
            <div className="explain">
              <div className="line" />
              <div className="text">
                ヘッドセットレンタルサービスで、
                <br />
                本格体験を！
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="tieup">
        <div className="title">
          <img src={titleTieUpService} alt="TIE-UP SERVICE" />
        </div>
        <div className="subtitle">企業・地方自治体の皆さま</div>
        <div className="image">
          <img src={imageTieUpService} alt="tie up service" />
        </div>
        <div className="explain">
          ViRTHでは、「ユーザーをお祝いする／ユーザーへのギフト」という観点で、これまでにない新しい接点でのコミュニケーション・プロモーション活動が可能となります。また、ご予算に応じてワールド内の空間や演出等、様々なタイアップが実現できます。
        </div>
        <div className="etc">
          <div className="etc-box-bg" />
          <div className="etc-box">
            オリジナルタイアップメニュー例
            <ul>
              <li>・会場空間タイアップ</li>
              <li>・セレブレートコンテンツタイアップ</li>
              <li>・アバタータイアップ</li>
              <li>・その他多数</li>
            </ul>
          </div>
        </div>
        <div className="text">β版期間にて“掲載費無料”実施中！</div>
        <TextButton
          label="お問い合わせはコチラから"
          onItemClicked={onMailClicked}
          variant="white"
          rightIcon={nextArrowPinkIcon}
        />
        <div className="attention">
          その他、企業・自治体用オリジナル空間へのカスタマイズ等可能です。ご関心をお持ちの方は、気軽にお問い合わせください。
        </div>
      </div>
      <div id="balloon">
        <img src={imageFooterVisual} alt="ViRTH" />
      </div>
    </div>
  </TeaserLayout>
);

export default TeaserPage;
