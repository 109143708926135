import Response from 'core/Response';
import axios from 'axios';
import accountIUMAPI from 'api/account-ium/api';
import ErrorResponse from 'core/ErrorResponse';
import { AuthData } from 'types/AuthData';

type ResponseData = {
  status: string;
};

const post = async (authData: AuthData): Promise<Response<ResponseData>> => {
  try {
    const response = await accountIUMAPI.post(`delete-account`, null, {
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${authData.token}`,
      },
    });

    return {
      success: true,
      data: response.data as ResponseData,
      status: response.status,
      statusText: response.statusText,
    };
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw new Error('API Calling Error: This is not Axios Error.');
    }

    return {
      success: false,
      data: {} as ResponseData,
      status: error.response?.status,
      statusText: error.message,
      errorData: error.response?.data as ErrorResponse,
    };
  }
};

export default post;
