import { useCallback, useEffect, useState, VFC } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import RoutePath from 'enums/RoutePath';
import AccountDeletePage from 'components/pages/AccountDeletePage';
import postDeleteAccountAPI from 'api/account-ium/delete-account/post';
import useAuth from 'hooks/use-auth';
import useShowAlert from 'hooks/use-show-alert';
import ErrorMessages from 'constants/ErrorMessages';

const EnhancedAccountDeletePage: VFC = () => {
  const navigate = useNavigate();
  const { readAuthData, logout, saveTokenFromPath } = useAuth();
  const [searchParams] = useSearchParams();
  const [forWebView, setForWebView] = useState<boolean>(false);
  const { showErrorAlert } = useShowAlert();

  const deleteAccount = useCallback(async () => {
    console.log('delete');
    const authData = readAuthData();

    if (!authData) {
      // eslint-disable-next-line no-console
      console.log("can't get authData.");

      return;
    }

    const { success, data, status } = await postDeleteAccountAPI(authData);
    if (success && data) {
      logout();
      navigate(
        RoutePath.ACCOUNT_DELETE_COMPLETED +
          (forWebView ? `?forWebView=true` : ''),
      );
    } else {
      // eslint-disable-next-line no-console
      console.log(
        `delete account failed. status:${status ? status.toString() : ''}`,
      );
      showErrorAlert(ErrorMessages.DELETE_ACCOUNT_FAILED);
    }
  }, [forWebView, logout, navigate, readAuthData, showErrorAlert]);

  const handleDeleteButtonClick = useCallback(() => {
    console.log('delete start');
    void deleteAccount();
  }, [deleteAccount]);

  useEffect(() => {
    const paramForWebView = searchParams.get('forWebView');
    if (paramForWebView === 'true') {
      setForWebView(true);
    }

    const paramToken = searchParams.get('token');
    if (paramToken && paramToken?.length > 0) {
      saveTokenFromPath(paramToken);
    } else {
      navigate(RoutePath.TEASER);
    }
  }, [forWebView, navigate, saveTokenFromPath, searchParams]);

  return (
    <AccountDeletePage
      forWebView={forWebView}
      onDeleteClicked={handleDeleteButtonClick}
    />
  );
};
export default EnhancedAccountDeletePage;
