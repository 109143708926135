import { ReactNode, useCallback, useState, VFC } from 'react';
import AppLayout from 'components/layouts/AppLayout';
import { PageKey } from 'types/Page';

type Props = {
  children: ReactNode;
  currentPage: PageKey;
  /**
   * type
   */
  type:
    | 'top'
    | 'privacy-policy'
    | 'terms'
    | 'app-terms'
    | 'register'
    | 'account'
    | 'accountDelete';
};
const EnhancedAppLayout: VFC<Props> = ({ children, currentPage, type }) => {
  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] =
    useState<boolean>(false);

  const handlePrivacyPolicyClicked = useCallback(() => {
    setIsPrivacyPolicyOpen(!isPrivacyPolicyOpen);
  }, [isPrivacyPolicyOpen]);

  return (
    <AppLayout
      onPrivacyPolicyClicked={handlePrivacyPolicyClicked}
      isPrivacyPolicyOpen={isPrivacyPolicyOpen}
      currentPage={currentPage}
      type={type}
    >
      {children}
    </AppLayout>
  );
};

export default EnhancedAppLayout;
