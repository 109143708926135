import { VFC } from 'react';
import 'styles/molecules/CustomDialog.scss';
import TextButton from 'components/atoms/TextButton';

export type Props = {
  isOpen: boolean;
  title?: string;
  text: string;
  subtext: string;
  needOkButton?: boolean;
  needCancelButton?: boolean;
  needCloseButton?: boolean;
  onOkButtonClick?: () => void;
  onCancelButtonClick?: () => void;
  onCloseButtonClick?: () => void;
};

const CustomDialog: VFC<Props> = ({
  isOpen,
  title,
  text,
  subtext,
  needOkButton = false,
  onOkButtonClick,
  needCancelButton = false,
  onCancelButtonClick,
  needCloseButton = false,
  onCloseButtonClick,
}) =>
  isOpen ? (
    <div>
      <div className="custom-dialog" />
      <div className="custom-dialog-inner">
        <div className="custom-dialog-title">{title}</div>
        <div className="custom-dialog-text">{text}</div>
        <div className="custom-dialog-subtext">{subtext}</div>
        <div className="custom-dialog-buttons">
          {needCancelButton ? (
            <div className="custom-dialog-button cunstom-dialog-cancel">
              <TextButton
                label="キャンセル"
                onItemClicked={onCancelButtonClick}
                variant="back"
              />
            </div>
          ) : undefined}
          {needOkButton ? (
            <div className="custom-dialog-button cunstom-dialog-ok">
              <TextButton
                label="OK"
                onItemClicked={onOkButtonClick}
                variant="fill"
              />
            </div>
          ) : undefined}
          {needCloseButton ? (
            <div className="custom-dialog-button cunstom-dialog-close">
              <TextButton
                variant="fill"
                label="閉じる"
                onItemClicked={onCloseButtonClick}
              />
            </div>
          ) : undefined}
        </div>
      </div>
    </div>
  ) : (
    <div />
  );

export default CustomDialog;
