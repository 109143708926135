import { VFC } from 'react';

import 'styles/pages/AccountDeleteCompletedPage.scss';
import TeaserLayout from 'containers/layouts/TeaserLayout';
import TextButton from 'components/atoms/TextButton';
import AppLayout from 'containers/layouts/AppLayout';

type Props = {
  onToTopClicked: () => void;
  forWebView: boolean;
};

const AccountDeleteCompletedPage: VFC<Props> = ({
  onToTopClicked,
  forWebView,
}) => (
  <div className="account-delete-complete">
    {forWebView ? (
      <AppLayout type="accountDelete" currentPage="accountDelete">
        <div id="account-delete-completed">
          <div className="title">退会手続きが完了しました。</div>
          <div className="text">
            ViRTHをご利用いただき、ありがとうございました。
            <br />
            またのご利用をお待ちしております。
          </div>
        </div>
      </AppLayout>
    ) : (
      <TeaserLayout type="accountDelete">
        <div id="account-delete-completed">
          <div className="title">退会手続きが完了しました。</div>
          <div className="text">
            ViRTHをご利用いただき、ありがとうございました。
            <br />
            またのご利用をお待ちしております。
          </div>
          <div className="button">
            <TextButton
              label="TOPページに戻る"
              onItemClicked={onToTopClicked}
              variant="back"
            />
          </div>
        </div>
      </TeaserLayout>
    )}
  </div>
);

export default AccountDeleteCompletedPage;
