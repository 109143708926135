import { useCallback, useState, VFC } from 'react';
import { useNavigate } from 'react-router-dom';
import RoutePath from 'enums/RoutePath';
import TeaserPage from 'components/pages/TeaserPage';

const EnhancedTeaserPage: VFC = () => {
  const navigate = useNavigate();

  const [isOverlayShow, setIsOverlayShow] = useState<boolean>(false);

  const [movies, setMovies] = useState<string[]>([]);

  const handleRegisterClicked = useCallback(() => {
    navigate(`${RoutePath.TEASER_REGISTER}`);
  }, [navigate]);

  const handleMailClicked = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log('handleMailClicked');
    window.location.href = 'mailto:virth@ium.co.jp';
  }, []);

  const handleMovieClicked = useCallback(() => {
    setIsOverlayShow(true);
    setMovies(['https://www.youtube.com/embed/nfmBjkgJgnI']);
  }, []);

  const handleMovieCloseClicked = useCallback(() => {
    setIsOverlayShow(false);
    setMovies([]);
  }, []);

  return (
    <TeaserPage
      onRegisterClicked={handleRegisterClicked}
      onMailClicked={handleMailClicked}
      onMovieClicked={handleMovieClicked}
      onMovieCloseClicked={handleMovieCloseClicked}
      isOverlayShow={isOverlayShow}
      movies={movies}
    />
  );
};
export default EnhancedTeaserPage;
