import { useEffect, VFC } from 'react';
import { useNavigate } from 'react-router-dom';
import RoutePath from 'enums/RoutePath';

const EnhancedTeaserRegisterCompletedPage: VFC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(RoutePath.TEASER_REGISTER_COMPLETED);
  }, [navigate]);

  return <>&nbsp;</>;
};
export default EnhancedTeaserRegisterCompletedPage;
