import axios from 'axios';
import getBaseIumAPIUrl from 'core/BaseIumAPIUri';

export default axios.create({
  baseURL: getBaseIumAPIUrl(),
  responseType: 'json',
  headers: {
    accept: 'application/json',
  },
});
