import { VFC } from 'react';

import 'styles/pages/RegisterCompletedPage.scss';
// import RegisterCompletedPage from 'containers/pages/RegisterCompletedPage';
import TeaserLayout from 'containers/layouts/TeaserLayout';
import Stepper from 'components/molecules/Stepper';
import RegisterSteps from 'constants/RegisterSteps';

type Props = {
  /**
   * Teaserかどうか
   */
  isTeaser?: boolean;
};

const TeaserRegsiterCompletedPage: VFC<Props> = () => (
  // <RegisterCompletedPage isTeaser />
  <TeaserLayout type="register">
    <div className="stepper-area">
      <Stepper stepNames={RegisterSteps} currentStep={3} />
    </div>
    <div className="register-steps current">
      <div id="register-completed">
        <h2>ようこそViRTHへ！</h2>
        <h3>11月初旬公開予定</h3>
        <div className="message-area">
          日本の街を貸し切り、エンタメ性と感動にあふれた
          <br />
          最高のパーティーをお楽しみください。
        </div>
      </div>
    </div>
  </TeaserLayout>
);

export default TeaserRegsiterCompletedPage;
