import { RefObject, VFC } from 'react';

import 'styles/organisms/AdminHeader.scss';

import Logo from 'components/organisms/Logo';
import MenuIcon from 'components/atoms/MenuIcon';
import UsageGuideInHeader from 'components/molecules/UsageGuideInHeader';
import LoginInHeader from 'components/molecules/LoginInHeader';
import RegisterInHeader from 'components/molecules/RegisterInHeader';
import InputCodeInHeader from 'components/molecules/InputCodeInHeader';
import InvitationCodeInputOverlay from './InvitationCodeInputOverlay';

type AdminHeaderProps = {
  /**
   * ロゴクリック時の関数
   */
  onLogoClicked: () => void;
  /**
   * メニューアイコンクリック時の関数
   */
  onMenuIconClicked: () => void;
  /**
   * 利用ガイドクリック時の関数
   */
  onUsageGuideClicked: () => void;
  /**
   * ログインクリック時の関数
   */
  onLoginClicked: () => void;
  /**
   * 新規登録クリック時の関数
   */
  onRegisterClicked: () => void;
  /**
   * 招待コード入力クリック時の関数
   */
  onInputCodeClicked: () => void;
  /**
   * 利用ガイドボタンを表示するかどうか
   */
  isShowUsageGuide?: boolean;
  /**
   * ログインボタンを表示するかどうか
   */
  isShowLogin?: boolean;
  /**
   * 新規登録ボタンを表示するかどうか
   */
  isShowRegister?: boolean;
  /**
   * 招待コード入力ボタンを表示するかどうか
   */
  isShowInputCode?: boolean;
  /**
   * メニューボタンを表示するかどうか
   */
  isShowMenuButton?: boolean;
  /**
   * メニューがオープン状態かどうか
   */
  isMenuOpened?: boolean;
  /**
   * 招待コード入力オーバーレイが開いているかどうか
   */
  isCodeInputOverlayOpen: boolean;
  /**
   * 招待コード入力オーバーレイが閉じているかどうか
   */
  onCodeInputOverlayClose: () => void;
  /**
   * 招待コード入力オーバーレイで確定されたかどうか
   */
  onCodeInputFixOverlay: () => void;
  /**
   * 招待コード入力欄のRef
   */
  codeInputRef: RefObject<HTMLInputElement>;
};

const AdminHeader: VFC<AdminHeaderProps> = ({
  onLogoClicked,
  onMenuIconClicked,
  onUsageGuideClicked,
  onLoginClicked,
  onRegisterClicked,
  onInputCodeClicked,
  isShowUsageGuide = true,
  isShowLogin = false,
  isShowRegister = false,
  isShowInputCode = false,
  isShowMenuButton = false,
  isMenuOpened = false,
  isCodeInputOverlayOpen,
  onCodeInputOverlayClose,
  onCodeInputFixOverlay,
  codeInputRef,
}) => (
  <>
    <div id="admin-header" className={isMenuOpened ? 'isOpen' : ''}>
      <div className="left-area">
        <Logo onItemClicked={onLogoClicked} type="header" place="admin" />
      </div>
      <div className="middle-area">
        {isShowInputCode ? (
          <InputCodeInHeader onItemClicked={onInputCodeClicked} />
        ) : undefined}
        {isShowUsageGuide ? (
          <UsageGuideInHeader onItemClicked={onUsageGuideClicked} />
        ) : undefined}
        {isShowLogin ? (
          <LoginInHeader onItemClicked={onLoginClicked} />
        ) : undefined}
        {isShowRegister ? (
          <RegisterInHeader onItemClicked={onRegisterClicked} />
        ) : undefined}
      </div>
      {isShowMenuButton ? (
        <div className="right-area">
          <MenuIcon isOpen={isMenuOpened} onItemClicked={onMenuIconClicked} />
        </div>
      ) : undefined}
    </div>
    <InvitationCodeInputOverlay
      isOpen={isCodeInputOverlayOpen}
      onClose={onCodeInputOverlayClose}
      onFixClicked={onCodeInputFixOverlay}
      inputRef={codeInputRef}
    />
  </>
);

export default AdminHeader;
