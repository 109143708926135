import {
  ChangeEvent,
  createRef,
  useCallback,
  useEffect,
  useState,
  VFC,
} from 'react';
import RegisterPage from 'components/pages/RegisterPage';
import postIumUser from 'api/ium/v1/users/post';
import postAccountRegisterAPI from 'api/account-ium/register/post';
import postCheckUserIdAPI from 'api/account-ium/check-user-id/post';
import useShowAlert from 'hooks/use-show-alert';
import ErrorMessages from 'constants/ErrorMessages';
import APIErrorMessages from 'constants/APIErrorMessages';
import RegisterSteps from 'constants/RegisterSteps';
import { TextFieldData } from 'types/TextFieldData';
import useWindowFunctions from 'hooks/use-window-functions';
import { useSearchParams } from 'react-router-dom';

type Props = {
  isTeaser?: boolean;
};

const EnhancedRegisterPage: VFC<Props> = ({ isTeaser = false }) => {
  const { showErrorAlert } = useShowAlert();
  const [searchParams] = useSearchParams();
  const [forWebView, setForWebView] = useState<boolean>(false);

  const { scrollToTopDirectly } = useWindowFunctions();

  const [currentStep, setCurrentStep] = useState<number>(0);

  const firstNameInputRef = createRef<HTMLInputElement>();
  const lastNameInputRef = createRef<HTMLInputElement>();
  const firstNameKanaInputRef = createRef<HTMLInputElement>();
  const lastNameKanaInputRef = createRef<HTMLInputElement>();
  const nicknameInputRef = createRef<HTMLInputElement>();
  const uniqueIdInputRef = createRef<HTMLInputElement>();
  const mailInputRef = createRef<HTMLInputElement>();
  const passInputRef = createRef<HTMLInputElement>();
  const passConfirmInputRef = createRef<HTMLInputElement>();
  const [firstNameValidate, setFirstNameValidate] = useState<boolean>(true);
  const [lastNameValidate, setLastNameValidate] = useState<boolean>(true);
  const [firstNameKanaValidate, setFirstNameKanaValidate] =
    useState<boolean>(true);
  const [lastNameKanaValidate, setLastNameKanaValidate] =
    useState<boolean>(true);
  const [nicknameValidate, setNicknameValidate] = useState<boolean>(true);
  const [uniqueIdValidate, setUniqueIdValidate] = useState<boolean>(true);
  const [mailValidate, setMaillValidate] = useState<boolean>(true);
  const [passValidate, setPassValidate] = useState<boolean>(true);
  const [passConfirmValidate, setPassConfirmValidate] = useState<boolean>(true);

  const [_firstName, _setFirstName] = useState<string>('');
  const handleFirstNameChange = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      const value = event.target.value as string;
      setFirstNameValidate(true);
      _setFirstName(value);
    },
    [],
  );

  const [_lastName, _setLastName] = useState<string>('');
  const handleLastNameChange = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      const value = event.target.value as string;
      setLastNameValidate(true);
      _setLastName(value);
    },
    [],
  );

  const [_firstNameKana, _setFirstNameKana] = useState<string>('');
  const handleFirstNameKanaChange = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      const value = event.target.value as string;
      setFirstNameKanaValidate(true);
      _setFirstNameKana(value);
    },
    [],
  );

  const [_lastNameKana, _setLastNameKana] = useState<string>('');
  const handleLastNameKanaChange = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      const value = event.target.value as string;
      setLastNameKanaValidate(true);
      _setLastNameKana(value);
    },
    [],
  );

  const [_nickname, _setNickname] = useState<string>('');
  const handleNicknameChange = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      const value = event.target.value as string;
      setNicknameValidate(true);
      _setNickname(value);
    },
    [],
  );

  const [_uniqueId, _setUniqueId] = useState<string>('');
  const handleUniqueIdChange = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      const value = event.target.value as string;
      setUniqueIdValidate(true);
      _setUniqueId(value);
    },
    [],
  );

  const [_email, _setEmail] = useState<string>('');
  const handleEmailChange = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      const value = event.target.value as string;
      setMaillValidate(true);
      _setEmail(value);
    },
    [],
  );

  const [_password, _setPassword] = useState<string>('');
  const handlePasswordChange = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      const value = event.target.value as string;
      setPassValidate(true);
      _setPassword(value);
    },
    [],
  );

  const [_passwordConfirm, _setPasswordConfirm] = useState<string>('');
  const handlePasswordConfirmChange = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      const value = event.target.value as string;
      setPassConfirmValidate(true);
      _setPasswordConfirm(value);
    },
    [],
  );

  const [_selectedYear, _setSelectedYear] = useState<number>();
  const handleSelectYearChange = useCallback((year: string) => {
    // eslint-disable-next-line no-console
    console.log('handleSelectYearChange');
    _setSelectedYear(parseInt(year, 10));
  }, []);

  const [_selectedMonth, _setSelectedMonth] = useState<number>();
  const handleSelectMonthChange = useCallback((month: string) => {
    // eslint-disable-next-line no-console
    console.log('handleSelectMonthChange');
    _setSelectedMonth(parseInt(month, 10));
  }, []);

  const [_selectedDay, _setSelectedDay] = useState<number>();
  const handleSelectDayChange = useCallback((day: string) => {
    // eslint-disable-next-line no-console
    console.log('handleSelectDayChange');
    _setSelectedDay(parseInt(day, 10));
  }, []);

  const [isBirthdayValidate, setIsBirthdayValidate] = useState<boolean>(true);

  const [isTermsOpen, setIsTermsOpen] = useState<boolean>(false);
  const handleTermsClicked = useCallback(() => {
    setIsTermsOpen(!isTermsOpen);
  }, [isTermsOpen]);

  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] =
    useState<boolean>(false);
  const handlePrivacyPolicyClicked = useCallback(() => {
    setIsPrivacyPolicyOpen(!isPrivacyPolicyOpen);
  }, [isPrivacyPolicyOpen]);

  const checkUser = useCallback(() => {
    setMaillValidate(true);
    setPassValidate(true);
    setLastNameValidate(true);
    setFirstNameValidate(true);
    setLastNameValidate(true);
    setFirstNameValidate(true);
    setFirstNameKanaValidate(true);
    setLastNameKanaValidate(true);
    setUniqueIdValidate(true);
    setIsBirthdayValidate(true);
    let errorCounter = 0;
    if (_email === '') {
      setMaillValidate(false);
      errorCounter += 1;
    }
    const regexEmail = /[\w\-._]+@[\w\-._]+\.[A-Za-z]+/;
    if (!regexEmail.test(_email)) {
      setMaillValidate(false);
      errorCounter += 1;
    }
    if (_password === '') {
      setPassValidate(false);
      errorCounter += 1;
    }
    if (_passwordConfirm === '') {
      setPassConfirmValidate(false);
      errorCounter += 1;
    }
    if (_password !== _passwordConfirm) {
      setPassValidate(false);
      setPassConfirmValidate(false);
      errorCounter += 1;
    }
    const regexNumber = /[0-9]/;
    const regexAlphabet = /[a-zA-Z]/;
    if (!regexNumber.test(_password) || !regexAlphabet.test(_password)) {
      setPassValidate(false);
      errorCounter += 1;
    }
    if (_lastName === '') {
      setLastNameValidate(false);
      setFirstNameValidate(false);
      errorCounter += 1;
    }
    if (_firstName === '') {
      setLastNameValidate(false);
      setFirstNameValidate(false);
      errorCounter += 1;
    }
    const regexKana = /^[ぁ-んァ-ンーヴゔ]+$/;
    if (!regexKana.test(_lastNameKana)) {
      setLastNameKanaValidate(false);
      errorCounter += 1;
    }
    if (!regexKana.test(_firstNameKana)) {
      setFirstNameKanaValidate(false);
      setLastNameKanaValidate(false);
      errorCounter += 1;
    }
    if (_lastNameKana === '') {
      setLastNameKanaValidate(false);
      errorCounter += 1;
    }
    if (_firstNameKana === '') {
      setFirstNameKanaValidate(false);
      errorCounter += 1;
    }
    if (_uniqueId === '' || _uniqueId.length < 4) {
      setUniqueIdValidate(false);
      errorCounter += 1;
    }
    if (_nickname === '') {
      setNicknameValidate(false);
      errorCounter += 1;
    }
    if (!_selectedYear || !_selectedMonth || !_selectedDay) {
      if (!_selectedYear && !_selectedMonth && !_selectedDay) {
        setIsBirthdayValidate(true);
      } else {
        setIsBirthdayValidate(false);
        errorCounter += 1;
      }
    }
    if (
      _email === '' ||
      _password === '' ||
      _password.length < 6 ||
      _passwordConfirm === '' ||
      _passwordConfirm.length < 6 ||
      _lastName === '' ||
      _firstName === '' ||
      _lastNameKana === '' ||
      _firstNameKana === '' ||
      _nickname === '' ||
      _nickname.length > 16 ||
      _uniqueId === '' ||
      _uniqueId.length < 4
      // _selectedYear === undefined ||
      // _selectedMonth === undefined ||
      // _selectedDay === undefined
    ) {
      // showErrorAlert(ErrorMessages.REGISTER_NOT_FILLED);

      return;
    }
    if (errorCounter > 0) {
      return;
    }

    scrollToTopDirectly();
    setCurrentStep(1);
  }, [
    _email,
    _firstName,
    _firstNameKana,
    _lastName,
    _lastNameKana,
    _nickname,
    _password,
    _passwordConfirm,
    _selectedDay,
    _selectedMonth,
    _selectedYear,
    _uniqueId,
    scrollToTopDirectly,
  ]);

  // TODO: 最終的には使わなくなる
  const postUser = useCallback(async () => {
    // TODO: APIが完成したらここで指定するものを増やす

    const registerData = {
      name: _nickname,
      uniqueId: _uniqueId,
      email: _email,
      password: _password,
      avatar: '',
    };
    const { success, data, status, errorData } = await postIumUser(
      registerData,
    );
    if (success && data) {
      // eslint-disable-next-line no-console
      console.log(`User created. id:${data.id}`);

      setCurrentStep(2);
    } else {
      // eslint-disable-next-line no-console
      console.log(`postUser failed. status:${status ? status.toString() : ''}`);
      showErrorAlert(ErrorMessages.REGISTER_FAILED);
      if (
        errorData &&
        errorData.messages.length > 0 &&
        errorData.messages[0].startsWith(
          APIErrorMessages.REGISTER_EMAIL_DUPLICATE,
        )
      ) {
        showErrorAlert(ErrorMessages.REGISTER_EMAIL_DUPLICATED);
      } else {
        showErrorAlert(ErrorMessages.REGISTER_FAILED);
      }
    }
  }, [_email, _nickname, _password, _uniqueId, showErrorAlert]);

  const { host, protocol } = window.location;

  const postRegister = useCallback(async () => {
    // 誕生日が空の場合は項目ごと送らない
    let registerData;
    // 全部空なら誕生日を外して通す
    if (!_selectedYear && !_selectedMonth && !_selectedDay) {
      registerData = {
        firstName: _firstName,
        lastName: _lastName,
        furiganaLastName: _lastNameKana,
        furiganaFirstName: _firstNameKana,
        nickname: _nickname,
        email: _email,
        password: _password,
        userId: _uniqueId,
        callback: `${protocol}//${host}/register/completed`,
      };
    } else {
      // 全部空の場合以外で何かが空の場合は通さない
      if (!_selectedYear || !_selectedMonth || !_selectedDay) {
        // eslint-disable-next-line no-console
        console.error('全部空の場合以外で、何かが空の場合は通さない');

        return;
      }
      const selectedMonthStr =
        _selectedMonth < 10 ? `0${_selectedMonth}` : _selectedMonth;
      const selectedDayStr =
        _selectedDay < 10
          ? `0${_selectedDay.toString()}`
          : _selectedDay.toString();
      const birthDay = `${_selectedYear}-${selectedMonthStr}-${selectedDayStr}`;

      registerData = {
        firstName: _firstName,
        lastName: _lastName,
        furiganaLastName: _lastNameKana,
        furiganaFirstName: _firstNameKana,
        nickname: _nickname,
        birthday: birthDay,
        email: _email,
        password: _password,
        userId: _uniqueId,
        callback: `${protocol}//${host}/register/completed`,
      };
    }

    const { success, data, status, errorData } = await postAccountRegisterAPI(
      registerData,
    );
    if (success && data) {
      // eslint-disable-next-line no-console
      console.log(`User created. id:${data.id}`);

      setCurrentStep(2);
    } else {
      // eslint-disable-next-line no-console
      console.log(
        `postRegister failed. status:${status ? status.toString() : ''}`,
      );
      showErrorAlert(ErrorMessages.REGISTER_FAILED);
      // eslint-disable-next-line no-console
      console.table(errorData);
      // if (
      //   errorData &&
      //   errorData.password > 0 &&
      //   errorData[0].startsWith(
      //     APIErrorMessages.REGISTER_EMAIL_DUPLICATE,
      //   )
      // ) {
      //   showErrorAlert(ErrorMessages.REGISTER_EMAIL_DUPLICATED);
      // } else {
      //   showErrorAlert(ErrorMessages.REGISTER_FAILED);
      // }
    }
  }, [
    _email,
    _firstName,
    _firstNameKana,
    _lastName,
    _lastNameKana,
    _nickname,
    _password,
    _selectedDay,
    _selectedMonth,
    _selectedYear,
    _uniqueId,
    host,
    protocol,
    showErrorAlert,
  ]);

  const postCheckUserId = useCallback(async () => {
    if (!_uniqueId) {
      return;
    }

    const registerData = {
      userId: _uniqueId,
    };

    const { success, data, status } = await postCheckUserIdAPI(registerData);

    if (success && data) {
      // eslint-disable-next-line no-console
      console.log(`User created. id:${data.message}`);
    } else {
      if (status === 401) {
        // eslint-disable-next-line no-console
        console.log(`user id already has used.`);
        showErrorAlert(ErrorMessages.REGISTER_LOGIN_ID_ALREADY_USED);
      }
      // eslint-disable-next-line no-console
      console.log(
        `postRegister failed. status:${status ? status.toString() : ''}`,
      );
    }
  }, [_uniqueId, showErrorAlert]);

  const handleRegisterButtonClicked = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log('handleRegisterButtonClicked');

    void checkUser();
    if (isTeaser) {
      void postCheckUserId();
    }
  }, [checkUser, isTeaser, postCheckUserId]);

  const handleBackClicked = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log('handleBackClicked');

    setCurrentStep(0);
  }, []);

  const handleConfirmButtonClicked = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log('handleConfirmButtonClicked');

    // TODO: 最終的にはティザーはなくなる
    // TODO: Fakeサーバーとの使い分けを整理する
    if (isTeaser) {
      // void postCheckUserId();
      void postRegister();
    } else {
      void postUser();
    }
  }, [isTeaser, postRegister, postUser]);

  useEffect(() => {
    scrollToTopDirectly();
  }, [scrollToTopDirectly]);

  useEffect(() => {
    const param = searchParams.get('forWebView');
    if (param === 'true') {
      setForWebView(true);
    }
  }, [forWebView, searchParams]);

  return (
    <RegisterPage
      forWebView={forWebView}
      isTeaser={isTeaser}
      stepNames={RegisterSteps}
      currentStep={currentStep}
      onRegisterClicked={handleRegisterButtonClicked}
      onBackClicked={handleBackClicked}
      onConfirmClicked={handleConfirmButtonClicked}
      firstNameData={
        {
          fieldName: '',
          fieldNameSub: '',
          isShowFieldName: true,
          isShowRequiredMark: false,
          placeholder: '名（漢字）',
          value: _firstName,
          inputRef: firstNameInputRef,
          validate: firstNameValidate,
          validateText: '',
          onChange: handleFirstNameChange,
          maxLength: 255,
        } as TextFieldData
      }
      lastNameData={
        {
          fieldName: '',
          fieldNameSub: '',
          isShowFieldName: true,
          isShowRequiredMark: false,
          placeholder: '姓（漢字）',
          value: _lastName,
          inputRef: lastNameInputRef,
          validate: lastNameValidate,
          validateText: '※氏名を入力してください。',
          onChange: handleLastNameChange,
          maxLength: 255,
        } as TextFieldData
      }
      firstNameKanaData={
        {
          fieldName: ' ',
          fieldNameSub: '',
          isShowFieldName: true,
          isShowRequiredMark: false,
          placeholder: '名（カナ）',
          value: _firstNameKana,
          inputRef: firstNameKanaInputRef,
          validate: firstNameKanaValidate,
          validateText: '',
          onChange: handleFirstNameKanaChange,
          maxLength: 255,
        } as TextFieldData
      }
      lastNameKanaData={
        {
          fieldName: '',
          fieldNameSub: '',
          isShowFieldName: true,
          isShowRequiredMark: false,
          placeholder: '姓（カナ）',
          value: _lastNameKana,
          inputRef: lastNameKanaInputRef,
          validate: lastNameKanaValidate,
          validateText: '※氏名をカナで入力してください。',
          onChange: handleLastNameKanaChange,
          maxLength: 255,
        } as TextFieldData
      }
      nicknameData={
        {
          fieldName: 'ニックネーム',
          fieldNameSub: '※16文字以内',
          isShowFieldName: true,
          isShowRequiredMark: true,
          placeholder: '',
          value: _nickname,
          inputRef: nicknameInputRef,
          validate: nicknameValidate,
          validateText: '※ニックネームを入力してください。',
          onChange: handleNicknameChange,
          maxLength: 16,
        } as TextFieldData
      }
      uniqueIdData={
        {
          fieldName: 'ログインID',
          fieldNameSub: '※英数字4文字以上',
          isShowFieldName: true,
          isShowRequiredMark: true,
          placeholder: '',
          value: _uniqueId,
          inputRef: uniqueIdInputRef,
          validate: uniqueIdValidate,
          validateText: '※ログインIDを入力してください。',
          onChange: handleUniqueIdChange,
          maxLength: 255,
          // minLength: 4,
        } as TextFieldData
      }
      mailData={
        {
          fieldName: 'メールアドレス',
          fieldNameSub: '',
          isShowFieldName: true,
          isShowRequiredMark: true,
          placeholder: '',
          value: _email,
          inputRef: mailInputRef,
          validate: mailValidate,
          validateText: '※メールアドレスを入力してください。',
          onChange: handleEmailChange,
          maxLength: 255,
          type: 'mail',
        } as TextFieldData
      }
      passData={
        {
          fieldName: 'パスワード',
          fieldNameSub: '※数字を1文字以上含む英数字6文字以上',
          isShowFieldName: true,
          isShowRequiredMark: true,
          placeholder: '',
          value: _password,
          inputRef: passInputRef,
          validate: passValidate,
          validateText: '※数字を1文字以上含む英数字6文字以上を入力してください',
          onChange: handlePasswordChange,
          maxLength: 255,
          type: 'password',
        } as TextFieldData
      }
      passConfirmData={
        {
          fieldName: 'パスワードを再入力',
          fieldNameSub: '',
          isShowFieldName: true,
          isShowRequiredMark: true,
          placeholder: '',
          value: _passwordConfirm,
          inputRef: passConfirmInputRef,
          validate: passConfirmValidate,
          validateText: '※数字を1文字以上含む英数字6文字以上を入力してください',
          onChange: handlePasswordConfirmChange,
          maxLength: 255,
          type: 'password',
        } as TextFieldData
      }
      onSelectYearChange={handleSelectYearChange}
      selectYear={_selectedYear}
      onSelectMonthChange={handleSelectMonthChange}
      selectMonth={_selectedMonth}
      onSelectDayChange={handleSelectDayChange}
      selectDay={_selectedDay}
      isBirthdayValidate={isBirthdayValidate}
      onTermsClicked={handleTermsClicked}
      onPrivacyPolicyClicked={handlePrivacyPolicyClicked}
      isTermsOpen={isTermsOpen}
      isPrivacyPolicyOpen={isPrivacyPolicyOpen}
    />
  );
};
export default EnhancedRegisterPage;
