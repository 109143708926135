import { VFC } from 'react';

import 'styles/atoms/MenuIcon.scss';

import menuImage from 'images/icon_menu.svg';
import closeWhenOpeningImage from 'images/icon_close_menu_opening.svg';

type MenuIconProps = {
  /**
   * メニューを開いているかどうか
   */
  isOpen?: boolean;
  /**
   * クリック時の関数
   */
  onItemClicked: () => void;
};

const MenuIcon: VFC<MenuIconProps> = ({
  isOpen = false,
  onItemClicked = () => undefined,
}) => (
  <div className="menu-icon">
    <button type="button" className="menu-icon" onClick={onItemClicked}>
      <img alt="menu" src={isOpen ? closeWhenOpeningImage : menuImage} />
    </button>
  </div>
);

export default MenuIcon;
