import { VFC } from 'react';

import 'styles/organisms/SelectBirthday.scss';

type Props = {
  /**
   * 年を選択した
   */
  onSelectYearChange: (value: string) => void;
  /**
   * 選択中の年
   */
  selectYear?: number;
  /**
   * 年の選択肢
   */
  years: { value: number; key: string; isSelected?: boolean }[];
  /**
   * 月を選択した
   */
  onSelectMonthChange: (value: string) => void;
  /**
   * 選択中の月
   */
  selectMonth?: number;
  /**
   * 月の選択肢
   */
  months: { value: number; key: string; isSelected?: boolean }[];
  /**
   * 日を選択した
   */
  onSelectDayChange: (value: string) => void;
  /**
   * 選択中の日
   */
  selectDay?: number;
  /**
   * 日の選択肢
   */
  days: { value: number; key: string; isSelected?: boolean }[];
  /**
   * validate状態
   */
  isValidate?: boolean;
  /**
   * validateText
   */
  validateText?: string;
};

const SelectBirthday: VFC<Props> = ({
  onSelectYearChange,
  selectYear,
  years,
  onSelectMonthChange,
  selectMonth,
  months,
  onSelectDayChange,
  selectDay,
  days,
  isValidate = true,
  validateText = '※年月日をすべて入力してください。',
}) => (
  <div className={`select-birthday${isValidate ? ' validate' : ' invalidate'}`}>
    <div className="field-name">生年月日</div>
    <div className="birthday-set">
      <select
        placeholder="選択してください"
        onChange={(event) => onSelectYearChange(event.currentTarget.value)}
        value={selectYear}
      >
        <option value="" key="noSelect">
          年
        </option>
        {years?.map((year) => (
          <option value={year.value} key={year.key}>
            {year.value}
          </option>
        ))}
      </select>
      <select
        placeholder="選択してください"
        onChange={(event) => onSelectMonthChange(event.currentTarget.value)}
        value={selectMonth}
      >
        <option value="" key="noSelect">
          月
        </option>
        {months?.map((month) => (
          <option value={month.value} key={month.key}>
            {month.value}
          </option>
        ))}
      </select>
      <select
        placeholder="選択してください"
        onChange={(event) => onSelectDayChange(event.currentTarget.value)}
        value={selectDay}
      >
        <option value="" key="noSelect">
          日
        </option>
        {days?.map((day) => (
          <option value={day.value} key={day.key}>
            {day.value}
          </option>
        ))}
      </select>
    </div>
    <p>{validateText}</p>
  </div>
);

export default SelectBirthday;
