import { VFC } from 'react';

import 'styles/organisms/EditPropertyItem.scss';

import TextButton from 'components/atoms/TextButton';
import TextConfirm from 'components/atoms/TextConfirm';
import IconEditReverse from 'images/icon_edit_reverse.svg';

type Props = {
  title: string;
  value: string;
  type?: 'default' | 'password';
  onClick?: () => void;
  isShowButton?: boolean;
};

const EditPropertyItem: VFC<Props> = ({
  title,
  value,
  type = 'default',
  onClick = () => undefined,
  isShowButton = true,
}) => (
  <div className="edit-property-item">
    <div className="text">
      <TextConfirm title={title} value={value} type={type} />
    </div>
    {isShowButton ? (
      <div className="button">
        <TextButton
          variant="black"
          label="編集する"
          leftIcon={IconEditReverse}
          onItemClicked={onClick}
        />
      </div>
    ) : undefined}
  </div>
);

export default EditPropertyItem;
