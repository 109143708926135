import { createRef, useCallback, useState, VFC } from 'react';
import SystemConsoleLogInPage from 'components/pages/SystemConsoleLogInPage';
import useShowAlert from 'hooks/use-show-alert';
import ErrorMessages from 'constants/ErrorMessages';
import useAuth from 'hooks/use-auth';
// import usePostInvitationCode from 'hooks/use-post-invitation-code';
// import useInvitationCode from 'hooks/use-invitation-code';

const EnhancedSystemConsoleLogInPage: VFC = () => {
  const { showErrorAlert } = useShowAlert();
  const { systemConsoleLogin } = useAuth();
  // const { readCode } = useInvitationCode();
  // const { postInvitationCode } = usePostInvitationCode();

  const mailInputRef = createRef<HTMLInputElement>();
  const passInputRef = createRef<HTMLInputElement>();
  const [mailValidate, setMaillValidate] = useState<boolean>(true);
  const [passValidate, setPassValidate] = useState<boolean>(true);
  // const [nextLoginFlg, setNextLoginFlg] = useState<boolean>(false);

  const [_email, _setEmail] = useState<string>('');
  const handleEmailChange = useCallback((value: string) => {
    setMaillValidate(true);
    _setEmail(value);
  }, []);

  const [_password, _setPassword] = useState<string>('');
  const handlePasswordChange = useCallback((value: string) => {
    setPassValidate(true);
    _setPassword(value);
  }, []);

  // メールアドレスとパスワードの、先頭と末尾の半角全角スペース確認
  // TODO: 他のチェック項目がある場合はチェック体制を再検討
  // TODO: チェックをリアルタイムに行う場合や、強制的にスペースを削除する仕様の場合も再検討
  const checkSpaceIncluded = useCallback((targetString: string) => {
    if (
      targetString.indexOf(' ') === 0 ||
      targetString.indexOf('　') === 0 ||
      targetString.lastIndexOf(' ') === targetString.length - 1 ||
      targetString.lastIndexOf('　') === targetString.length - 1
    ) {
      return false;
    }

    return true;
  }, []);

  const authorize = useCallback(() => {
    if (_email === '') {
      setMaillValidate(false);
    }
    if (_password === '') {
      setPassValidate(false);
    }
    if (_email === '' || _password === '') {
      showErrorAlert(ErrorMessages.LOGIN_NOT_FILLED);

      return;
    }

    if (!checkSpaceIncluded(_email)) {
      showErrorAlert(ErrorMessages.SPACE_INCLUDED);

      return;
    }
    if (!checkSpaceIncluded(_password)) {
      showErrorAlert(ErrorMessages.SPACE_INCLUDED);

      return;
    }

    if (systemConsoleLogin(_email, _password)) {
      // eslint-disable-next-line no-console
      console.log('login success!');
    }
  }, [
    _email,
    _password,
    checkSpaceIncluded,
    systemConsoleLogin,
    showErrorAlert,
  ]);

  const handleloginButtonClick = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log('handleloginButtonClick');
    void authorize();
  }, [authorize]);

  // const handleCheckBoxChanged = useCallback(() => {
  //   // eslint-disable-next-line no-console
  //   console.log('onCheckBoxChanged');
  //   setNextLoginFlg((flg) => !flg);
  // }, []);

  return (
    <SystemConsoleLogInPage
      onLogInClicked={handleloginButtonClick}
      mailInputRef={mailInputRef}
      passInputRef={passInputRef}
      mailValidate={mailValidate}
      passValidate={passValidate}
      onEmailChange={handleEmailChange}
      onPasswordChange={handlePasswordChange}
      // onCheckBoxChanged={handleCheckBoxChanged}
      // nextLoginChecked={nextLoginFlg}
    />
  );
};
export default EnhancedSystemConsoleLogInPage;
