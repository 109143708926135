import { useCallback, useEffect, useState, VFC } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import RoutePath from 'enums/RoutePath';
import AccountDeleteCompletedPage from 'components/pages/AccountDeleteCompletedPage';

const EnhancedAccountDeleteCompletedPage: VFC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [forWebView, setForWebView] = useState<boolean>(false);

  const handleToTopButtonClick = useCallback(() => {
    navigate(RoutePath.TEASER);
  }, [navigate]);

  useEffect(() => {
    const paramForWebView = searchParams.get('forWebView');
    if (paramForWebView === 'true') {
      setForWebView(true);
    } else {
      navigate(RoutePath.TEASER);
    }
  }, [forWebView, navigate, searchParams]);

  return (
    <AccountDeleteCompletedPage
      forWebView={forWebView}
      onToTopClicked={handleToTopButtonClick}
    />
  );
};
export default EnhancedAccountDeleteCompletedPage;
