import { VFC } from 'react';

import 'styles/atoms/ImageButton.scss';

import defaultNoImage from 'images/default_no_image.svg';

type ImageButtonProps = {
  /**
   * image path
   * ※サイズは横幅いっぱいに表示されるので親要素で調整する
   * ※画像そのもののサイズを表示したい場合は背景画像での実装を検討する
   */
  image: string;
  /**
   * 説明テキスト
   */
  alt: string;
  /**
   * 有効かどうか
   */
  isEnabled?: boolean;
  /**
   * クリック時の関数
   */
  onItemClicked: () => void;
};

const ImageButton: VFC<ImageButtonProps> = ({
  image = '',
  alt = '',
  isEnabled = true,
  onItemClicked = () => undefined,
}) => (
  <div className="image-button">
    <button
      type="button"
      className={`image-button ${isEnabled ? 'enabled' : 'disabled'}`}
      onClick={onItemClicked}
      disabled={!isEnabled}
    >
      <img alt={alt} src={image || defaultNoImage} />
    </button>
  </div>
);

export default ImageButton;
