import { VFC } from 'react';

import 'styles/molecules/RegisterInHeader.scss';

type Props = {
  /**
   * 説明テキスト
   */
  label?: string;
  /**
   * 有効かどうか
   */
  isEnabled?: boolean;
  /**
   * クリック時の関数
   */
  onItemClicked: () => void;
};

const RegisterInHeader: VFC<Props> = ({
  label = '新規登録',
  isEnabled = true,
  onItemClicked = () => undefined,
}) => (
  <div className="register-in-header">
    <button
      type="button"
      className="register-button"
      onClick={onItemClicked}
      disabled={!isEnabled}
    >
      <p>{label}</p>
    </button>
  </div>
);

export default RegisterInHeader;
