import { useCallback, VFC } from 'react';
import { useNavigate } from 'react-router-dom';
import RoutePath from 'enums/RoutePath';
// import useAuth from 'hooks/use-auth';

import Menu from 'components/organisms/Menu';

import { Page, PageKey } from 'types/Page';

type Props = {
  isEnabled?: boolean;
  isLogin?: boolean;
  currentPage: PageKey;
};
const EnhancedMenu: VFC<Props> = ({
  isLogin = false,
  isEnabled = true,
  currentPage,
}) => {
  const navigate = useNavigate();
  // const { logout } = useAuth();

  // TODO: 実装の仕方では外に出す
  const pagesWhenLogin: Page[] = [
    {
      key: 'terms',
      label: '利用規約',
      path: RoutePath.TERMS,
      type: 'small',
      icon: '',
      iconCurrent: '',
    },
    {
      key: 'privacyPolicy',
      label: 'プライバシーポリシー',
      path: RoutePath.PRIVACY_POLICY,
      type: 'small',
      icon: '',
      iconCurrent: '',
    },
  ];
  const pagesWhenLogout: Page[] = [
    {
      key: 'terms',
      label: '利用規約',
      path: RoutePath.TERMS,
      type: 'small',
      icon: '',
      iconCurrent: '',
    },
    {
      key: 'privacyPolicy',
      label: 'プライバシーポリシー',
      path: RoutePath.PRIVACY_POLICY,
      type: 'small',
      icon: '',
      iconCurrent: '',
    },
  ];

  const handleLogout = useCallback(() => {
    navigate(RoutePath.TEASER);
    // logout();
  }, [navigate]);

  const handleItemClicked = useCallback(
    (key: PageKey, path: string, link?: string) => {
      // eslint-disable-next-line no-console
      console.log(`handleItemClicked ${path}`);

      navigate(path);

      if (key === 'logout') {
        handleLogout();
      }
      if (link && link?.length > 0) {
        window.open(link);
      }
    },
    [handleLogout, navigate],
  );

  return (
    <Menu
      currentPage={currentPage}
      onItemClicked={handleItemClicked}
      pagesWhenLogin={pagesWhenLogin}
      pagesWhenLogout={pagesWhenLogout}
      isEnabled={isEnabled}
      isLogin={isLogin}
    />
  );
};

export default EnhancedMenu;
