import { useCallback } from 'react';

const useWindowFunctions = (): {
  scrollToTop: (behavior?: 'auto' | 'smooth') => void;
  scrollToTopDirectly: () => void;
} => {
  const scrollToTop = useCallback((behavior: 'auto' | 'smooth' = 'auto') => {
    window.scrollTo({ top: 0, behavior });
  }, []);

  const scrollToTopDirectly = useCallback(() => {
    if (window && window.scroll) {
      window.scroll(0, 0);
    }
  }, []);

  return {
    scrollToTop,
    scrollToTopDirectly,
  };
};

export default useWindowFunctions;
