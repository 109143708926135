const getBaseIumAPIUrl = (): string => {
  const { hostname } = window.location;

  if (
    hostname.startsWith('dev-virth-app') ||
    hostname.startsWith('stg-virth-app')
  ) {
    return 'https://ium-fake-api.honeycomb-lab.co.jp/api';
  }

  return 'http://localhost:8080/api';
};

export default getBaseIumAPIUrl;
