import { VFC } from 'react';

import 'styles/molecules/LoginInHeader.scss';

import loginImage from 'images/icon_header_login.svg';

type Props = {
  /**
   * 説明テキスト
   */
  alt?: string;
  /**
   * 有効かどうか
   */
  isEnabled?: boolean;
  /**
   * クリック時の関数
   */
  onItemClicked: () => void;
};

const LoginInHeader: VFC<Props> = ({
  alt = 'ログイン',
  isEnabled = true,
  onItemClicked = () => undefined,
}) => (
  <div className="login-in-header">
    <button
      type="button"
      className="login-button"
      onClick={onItemClicked}
      disabled={!isEnabled}
    >
      <img src={loginImage} alt={alt} />
      <p>{alt}</p>
    </button>
  </div>
);

export default LoginInHeader;
