import { useCallback, useEffect, useState } from 'react';
import getIumUserAPI from 'api/account-ium/user-info/get';
import { AccountData } from 'types/AccountData';
import ErrorMessages from 'constants/ErrorMessages';
import useAuth from './use-auth';
import useShowAlert from './use-show-alert';

const useGetAccountInfo = (): {
  loadAccountInfo: (needShowAlert?: boolean, needLogout?: boolean) => void;
  accountInfo: AccountData;
} => {
  const { readAuthData, logout } = useAuth();
  const [_accountInfo, _setAccountInfo] = useState<AccountData>(
    {} as AccountData,
  );
  const { showErrorAlert } = useShowAlert();

  const loadAccountInfo = useCallback(
    async (needShowAlert = false, needLogout = false) => {
      const authData = readAuthData();

      if (!authData) {
        // eslint-disable-next-line no-console
        console.log("can't get authData.");

        return;
      }
      const { success, data, status } = await getIumUserAPI(authData);
      if (success && data) {
        _setAccountInfo({
          userId: data.userId,
          firstName: data.firstName,
          lastName: data.lastName,
          furiganaLastName: data.furiganaLastName,
          furiganaFirstName: data.furiganaFirstName,
          nickname: data.nickname,
          birthday: data.birthday,
          email: data.email,
          avatar: data.avatar,
        } as AccountData);
      } else {
        // eslint-disable-next-line no-console
        console.log(`loadUserInfo failed.${status ? status.toString() : ''}`);
        if (status === 401) {
          if (needShowAlert) showErrorAlert(ErrorMessages.NOT_AUTHORIZED);
          if (needLogout) logout();
        }
      }
    },
    [logout, readAuthData, showErrorAlert],
  );

  useEffect(() => {
    void loadAccountInfo();
  }, [loadAccountInfo]);

  return {
    loadAccountInfo,
    accountInfo: _accountInfo,
  };
};

export default useGetAccountInfo;
