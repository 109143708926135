import { ReactNode, VFC } from 'react';
// import AdminFooter from 'components/organisms/AdminFooter';

import 'styles/layouts/TeaserLayout.scss';
import Logo from 'components/organisms/Logo';
import COPYRIGHT_KICK from 'constants/CopyrightKick';

type Props = {
  children: ReactNode;
  /**
   * type
   */
  type: 'login';
};

const SystemConsoleLayout: VFC<Props> = ({ children, type = 'login' }) => (
  <div id="teaser-layout">
    <div className="teaser-layout-inner">
      <div id="admin-header">
        <div className="left-area">
          <Logo onItemClicked={() => undefined} type="header" place="admin" />
        </div>
      </div>
      <div id="content" className={type}>
        {children}
      </div>
      <div id="admin-footer">
        <div id="copyright">{COPYRIGHT_KICK}</div>
      </div>
    </div>
  </div>
);

export default SystemConsoleLayout;
