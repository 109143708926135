import { VFC } from 'react';

import 'styles/pages/TeaserPage.scss';
import RegisterPage from 'containers/pages/RegisterPage';

type Props = {
  /**
   * Teaserかどうか
   */
  isTeaser?: boolean;
};

const TeaserRegsiterPage: VFC<Props> = () => <RegisterPage isTeaser />;

export default TeaserRegsiterPage;
