import { useCallback } from 'react';

const useLocalStorage = (): {
  save: (key: string, value: string) => void;
  read: (key: string) => string | null;
  remove: (key: string) => void;
  clear: () => void;
} => {
  const save = useCallback((key: string, value: string) => {
    const myLocalStorage = localStorage;
    myLocalStorage.setItem(key, value);
  }, []);

  const read = useCallback((key: string) => {
    const myLocalStorage = localStorage;

    return myLocalStorage.getItem(key);
  }, []);
  const remove = useCallback((key: string) => {
    const myLocalStorage = localStorage;
    myLocalStorage.removeItem(key);
  }, []);
  const clear = useCallback(() => {
    const myLocalStorage = localStorage;
    myLocalStorage.clear();
  }, []);

  return {
    save,
    read,
    remove,
    clear,
  };
};

export default useLocalStorage;
