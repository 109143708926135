import { ReactNode, useCallback, VFC } from 'react';
import TeaserLayout from 'components/layouts/TeaserLayout';
import { useNavigate } from 'react-router-dom';
import RoutePath from 'enums/RoutePath';

type Props = {
  children: ReactNode;
  type:
    | 'top'
    | 'privacy-policy'
    | 'terms'
    | 'register'
    | 'account'
    | 'accountDelete';
};
const EnhancedTeaserLayout: VFC<Props> = ({ children, type }) => {
  const navigate = useNavigate();

  const handleLogoClicked = useCallback(() => {
    navigate(`${RoutePath.TEASER}`);
  }, [navigate]);

  const handleRegisterClicked = useCallback(() => {
    navigate(`${RoutePath.TEASER_REGISTER}`);
  }, [navigate]);

  return (
    <div>
      <TeaserLayout
        onLogoClicked={handleLogoClicked}
        onRegisterClicked={handleRegisterClicked}
        type={type}
      >
        {children}
      </TeaserLayout>
    </div>
  );
};

export default EnhancedTeaserLayout;
