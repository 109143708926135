import { VFC } from 'react';

import 'styles/organisms/RegisterStepperSent.scss';

import PageHeader from 'components/molecules/PageHeader';

type Props = {
  /**
   * Currentかどうか
   */
  isCurrent?: boolean;
  /**
   * 送信した先のメールアドレス
   */
  mail: string;
};

const RegisterStepperSent: VFC<Props> = ({ isCurrent = false, mail }) => (
  <div className={`register-steps ${isCurrent ? 'current' : ''}`}>
    <PageHeader label="メール認証" />
    <div className="sent-area">
      <div className="message-area">
        {mail}宛に認証メールを送信しました。
        <br />
        メールを開き、60分以内に記載されているURLをクリックして、ViRTHへの登録を完了してください。
      </div>
      <div className="hr" />
      <h3>メールが届かない場合</h3>
      <div className="message-area small">
        認証メールが届かない場合は以下をご確認ください。
        <br />
        <ul>
          <li>迷惑メールフォルダに振り分けられていないかご確認ください。</li>
          <li>
            メールの配信に時間がかかる場合があります。しばらく待った上で、メールが届いているかご確認ください。
          </li>
          <li>
            virth.jpドメインが受信拒否設定に含まれていないかご確認ください。
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default RegisterStepperSent;
