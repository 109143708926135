import { VFC } from 'react';

import 'styles/pages/NotFoundPage.scss';
import BasicLayout from 'containers/layouts/BasicLayout';

const NotFoundPage: VFC = () => (
  <BasicLayout>
    <div id="not-found" className="common-page">
      <h2>NOT FOUND</h2>
    </div>
  </BasicLayout>
);

export default NotFoundPage;
