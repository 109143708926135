import { VFC } from 'react';

import 'styles/atoms/TextField.scss';
import { TextFieldData } from 'types/TextFieldData';

type TextFieldProps = TextFieldData;

const TextField: VFC<TextFieldProps> = ({
  type = 'text',
  placeholder,
  fieldName = '',
  fieldNameSub = '',
  isShowFieldName = false,
  isShowRequiredMark = false,
  defaultValue,
  value,
  validate = true,
  validateText = '',
  onChange,
  inputRef,
  maxLength = 100,
  // minLength = 0,
  customClassName = '',
  isEnabled = true,
  isContentEditable = true,
  readOnly = false,
}) => (
  <div
    className={`text-field-area${validate ? ' validate' : ' invalidate'}${
      isEnabled ? '' : ' disabled'
    }${isShowFieldName ? ' include-field-name' : ''}`}
  >
    {isShowFieldName ? (
      <div className="field-name">
        {fieldName}
        <span className="required">{isShowRequiredMark ? '*' : ''}</span>
        <span className="sub">{fieldNameSub}</span>
      </div>
    ) : undefined}
    <div className={`text-field ${customClassName}`}>
      <input
        type={type}
        placeholder={placeholder}
        defaultValue={defaultValue || undefined}
        onChange={onChange}
        ref={inputRef}
        maxLength={maxLength}
        // minLength={minLength}
        disabled={!isEnabled}
        value={value || undefined}
        contentEditable={isContentEditable}
        readOnly={readOnly}
      />
    </div>
    <p>{validateText}</p>
  </div>
);

export default TextField;
