import { RefObject, VFC } from 'react';

import 'styles/pages/LogInPage.scss';
// import MenuLayout from 'containers/layouts/MenuLayout';
import TextField from 'components/atoms/TextField';
// import CheckBox from 'components/atoms/CheckBox';
import TextButton from 'components/atoms/TextButton';
import PageHeader from 'components/molecules/PageHeader';
import SystemConsoleLayout from 'components/layouts/SystemConsoleLayout';
// import { Link } from 'react-router-dom';
// import RoutePath from 'enums/RoutePath';

type Props = {
  /**
   * ログインボタンクリック時
   */
  onLogInClicked: () => void;
  /**
   * 入力項目
   */
  mailInputRef: RefObject<HTMLInputElement>;
  passInputRef: RefObject<HTMLInputElement>;
  mailValidate: boolean;
  passValidate: boolean;
  onEmailChange: (value: string) => void;
  onPasswordChange: (value: string) => void;
  // onCheckBoxChanged: () => void;
  // nextLoginChecked: boolean;
};

const SystemConsoleLogInPage: VFC<Props> = ({
  onLogInClicked,
  mailInputRef,
  passInputRef,
  mailValidate,
  passValidate,
  onEmailChange,
  onPasswordChange,
  // onCheckBoxChanged,
  // nextLoginChecked,
}) => (
  <SystemConsoleLayout
    type="login"
    // currentPage="login"
    // isShowLogin={false}
    // isShowRegister={false}
    // isShowUsageGuide={false}
  >
    <div id="login">
      <PageHeader label="ログイン" />
      <div className="login-area">
        <div className="input-area">
          <TextField
            fieldName="メールアドレスまたはログインID"
            isShowFieldName
            placeholder=""
            inputRef={mailInputRef}
            onChange={(event) => onEmailChange(event.target.value as string)}
            maxLength={256}
            validate={mailValidate}
            validateText="メールアドレスまたはログインIDを入力してください。"
            key="email"
          />
          <TextField
            type="password"
            fieldName="パスワード"
            isShowFieldName
            placeholder=""
            inputRef={passInputRef}
            onChange={(event) => onPasswordChange(event.target.value as string)}
            validate={passValidate}
            validateText="パスワードを入力してください。"
            key="pass"
          />
        </div>
        {/* <div className="checkbox-area">
          <CheckBox
            size="default"
            labels={['次回から自動的にログインする']}
            checkboxId="nextlogin"
            onItemChanged={onCheckBoxChanged}
            itemChecked={nextLoginChecked}
          />
        </div> */}
        <div className="login-button">
          <TextButton label="ログイン" onItemClicked={onLogInClicked} />
        </div>
        {/* <div className="forgot">
          <Link to={RoutePath.PASSWORD_FORGOT}>
            パスワードを忘れた場合はこちら
          </Link>
        </div> */}
      </div>
    </div>
  </SystemConsoleLayout>
);

export default SystemConsoleLogInPage;
