import { ErrorMessageSetting } from 'types/ErrorMessagesSetting';

// E00000 -> all
// E00001 -> login
// E00002 -> auth
const ErrorMessages = {
  SERVER_ERROR: {
    target: 'エラーが発生しました。',
    subText: 'もう一度、同じ操作をお試しください。',
    status: -1,
    errorText: 'server error',
    errorCode: 'E0000-0001',
  } as ErrorMessageSetting,
  LOGIN_FAILD: {
    target: 'メールアドレスまたはパスワードが間違っています。',
    subText: '',
    status: -1,
    errorText: 'login failed',
    errorCode: 'E00001-0001',
  } as ErrorMessageSetting,
  LOGIN_NOT_FILLED: {
    target: 'メールアドレスとパスワードの両方を入力してください。',
    subText: '',
    status: -1,
    errorText: 'login not filled',
    errorCode: 'E00001-0002',
  } as ErrorMessageSetting,
  SPACE_INCLUDED: {
    target:
      'メールアドレス / パスワードの先頭または末尾にスペースが含まれています。',
    subText: '',
    status: -1,
    errorText: 'Space included in the email or password',
    errorCode: 'E00001-0003',
  } as ErrorMessageSetting,
  NOT_AUTHORIZED: {
    target: 'ログインしてからアクセスしてください。',
    subText: '',
    status: -1,
    errorText: 'not authorized',
    errorCode: 'E00002-0001',
  } as ErrorMessageSetting,
  NO_REFRESH_TOKEN: {
    target: '認証に失敗しました。',
    subText: '',
    status: -1,
    errorText: 'no refresh token',
    errorCode: 'E00002-0002',
  } as ErrorMessageSetting,
  REFRESH_TOKEN_DISABLED: {
    target: '認証に失敗しました。ログアウトします。',
    subText: '',
    status: -1,
    errorText: 'refresh disabled, logout',
    errorCode: 'E00002-0003',
  } as ErrorMessageSetting,
  /**
   * パーティを作るときのエラー
   */
  CREATE_PARTY_ERROR: {
    target: 'すべての情報を入力してください。',
    subText: '',
    status: -1,
    errorText: 'create party error',
    errorCode: 'E00003-0001',
  } as ErrorMessageSetting,
  CREATE_PARTY_ERROR_DATE_FORMAT: {
    target: '日時の指定を正しく入力してください。',
    subText:
      '日時の指定はYYYY/MM/DD、時間の指定はHH:mmという形式で入力してください。',
    status: -1,
    errorText: 'create party error (date format)',
    errorCode: 'E00003-0002',
  } as ErrorMessageSetting,
  CREATE_PARTY_ERROR_NEED_SELECT_WORLD: {
    target: '会場を選択してください。',
    subText: '',
    status: -1,
    errorText: 'need select venue error',
    errorCode: 'E00003-0003',
  } as ErrorMessageSetting,
  CREATE_PARTY_ERROR_NEED_SELECT_PLAN: {
    target: 'プランを選択してください。',
    subText: '',
    status: -1,
    errorText: 'need select plan error',
    errorCode: 'E00003-0004',
  } as ErrorMessageSetting,
  CREATE_PARTY_ERROR_API_ERROR: {
    target:
      'データを登録できませんでした。時間をおいてもう一度試してください。',
    subText: '',
    status: -1,
    errorText: 'create party error (API error)',
    errorCode: 'E00003-0005',
  } as ErrorMessageSetting,
  CREATE_INVITATION_NEED_MESSAGE: {
    target: 'メッセージを入力してください。',
    subText: '',
    status: -1,
    errorText: 'create invitation error. need message.',
    errorCode: 'E00004-0001',
  } as ErrorMessageSetting,
  CREATE_INVITATION_ERROR_DONE_PARTY: {
    target: '過去のパーティに招待状を登録することはできません。',
    subText: '',
    status: -1,
    errorText: 'create invitation error (DONE Party)',
    errorCode: 'E00004-0002',
  } as ErrorMessageSetting,
  CREATE_INVITATION_ERROR_API_ERROR: {
    target:
      'データを登録できませんでした。時間をおいてもう一度試してください。',
    subText: '',
    status: -1,
    errorText: 'create invitation error (API error)',
    errorCode: 'E00004-0003',
  } as ErrorMessageSetting,
  REGISTER_NOT_FILLED: {
    target: 'すべての項目を入力してください。',
    subText: '',
    status: -1,
    errorText: 'register not filled',
    errorCode: 'E00005-0001',
  } as ErrorMessageSetting,
  REGISTER_EMAIL_DUPLICATED: {
    target: 'すでに同じメールアドレスで登録されています。',
    subText: '',
    status: -1,
    errorText: 'register duplicated.',
    errorCode: 'E00005-0002',
  } as ErrorMessageSetting,
  REGISTER_LOGIN_ID_ALREADY_USED: {
    target: 'すでに同じログインIDが登録されています。',
    subText: '他のログインIDを入力してください。',
    status: -1,
    errorText: 'register duplicated.',
    errorCode: 'E00005-0002',
  } as ErrorMessageSetting,
  REGISTER_FAILED: {
    target: 'ユーザー登録できませんでした。',
    subText: '',
    status: -1,
    errorText: 'register failed.',
    errorCode: 'E00005-0003',
  } as ErrorMessageSetting,
  DELETE_ACCOUNT_FAILED: {
    target: '退会できませんでした。',
    subText: '',
    status: -1,
    errorText: 'delete account failed.',
    errorCode: 'E00005-0003',
  } as ErrorMessageSetting,
};
export default ErrorMessages;
