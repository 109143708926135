import { VFC } from 'react';

import 'styles/atoms/MoviePlayButton.scss';
import ImageButton from 'components/atoms/ImageButton';
import playMovieIcon from 'images/lp/icon_play_movie.svg';
import fireworksImage from 'images/lp/image_fireworks.png';

type Props = {
  /**
   * 動画ボタンクリック時
   */
  onMovieClicked: () => void;
};

const MoviePlayButton: VFC<Props> = ({ onMovieClicked = () => undefined }) => (
  <div className="movie-play-button">
    <div className="base-layer">
      <ImageButton
        alt="動画"
        onItemClicked={onMovieClicked}
        image={fireworksImage}
      />
    </div>
    <div className="over-layer">
      <ImageButton
        alt="play"
        onItemClicked={onMovieClicked}
        image={playMovieIcon}
      />
    </div>
  </div>
);

export default MoviePlayButton;
