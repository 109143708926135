import axios from 'axios';
import getBaseAccountIumAPIUrl from 'core/BaseAccountIumAPIUri';

export default axios.create({
  baseURL: getBaseAccountIumAPIUrl(),
  responseType: 'json',
  headers: {
    accept: 'application/json',
  },
});
