import { useCallback, useEffect, useState, VFC } from 'react';
import AccountPage from 'components/pages/AccountPage';
import useGetAccountInfo from 'hooks/use-get-account-info';
import { useNavigate, useSearchParams } from 'react-router-dom';
import RoutePath from 'enums/RoutePath';
import AccountEditType from 'types/AccountEditType';
import useAuth from 'hooks/use-auth';

const EnhancedAccountPage: VFC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [forWebView, setForWebView] = useState<boolean>(false);
  const [token, setToken] = useState<string>('');
  const { accountInfo, loadAccountInfo } = useGetAccountInfo();
  const { saveTokenFromPath } = useAuth();

  const handleButtonClicked = useCallback((type: AccountEditType) => {
    // eslint-disable-next-line no-console
    console.log(`handleButtonClicked type:${type}`);

    // navigate(`${RoutePath.ACCOUNT_EDIT}/${type}`);
  }, []);

  useEffect(() => {
    const paramForWebView = searchParams.get('forWebView');
    if (paramForWebView === 'true') {
      setForWebView(true);
    }

    const paramToken = searchParams.get('token');
    if (paramToken && paramToken?.length > 0) {
      saveTokenFromPath(paramToken);
      loadAccountInfo();
      setToken(paramToken);
    } else {
      navigate(RoutePath.TEASER);
    }
  }, [forWebView, loadAccountInfo, navigate, saveTokenFromPath, searchParams]);

  return (
    <AccountPage
      forWebView={forWebView}
      onClick={handleButtonClicked}
      accountInfo={accountInfo}
      deleteLinkUrl={
        RoutePath.ACCOUNT_DELETE +
        (forWebView ? `?forWebView=true&token=${token}` : '')
      }
    />
  );
};
export default EnhancedAccountPage;
