import BackButton from 'components/molecules/BackButton';
import { VFC } from 'react';

import 'styles/molecules/PageHeader.scss';

type PageHeaderProps = {
  /**
   * ラベル
   */
  label: string;
  /**
   * variant
   */
  variant?: 'main' | 'sub';
  /**
   *
   */
  displayBack?: boolean;
  /**
   * Backクリック時の関数
   */
  onBackButtonClicked?: () => void;
};

const PageHeader: VFC<PageHeaderProps> = ({
  label,
  variant = 'main',
  displayBack = false,
  onBackButtonClicked = () => undefined,
}) => (
  <div className={`page-header page-header-${variant}`}>
    {displayBack ? (
      <BackButton
        alt="back"
        size="medium"
        onItemClicked={onBackButtonClicked}
      />
    ) : undefined}
    {label}
  </div>
);

export default PageHeader;
